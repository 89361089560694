import * as d3 from 'd3'
import moment from 'moment'
import { memo, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import SFApi from 'services/api/sfApi'
import dateTimeFormatting from 'services/formatting/dateTimeFormatting'
import globals from 'services/global/globals'
import { globalActions } from 'store/globalStore'
import { D3Selection, SVGEL } from 'types/d3TypeHelpers'
import { DetailData } from 'types/DetailItem'
import GraphSchedule from 'types/GraphSchedule'
import {
    DetailDataWithTimelineTicks,
    Palette,
    RightAxisType,
    ScheduleDetailsViewMode,
    TimeModeEnum,
} from 'types/interfaces'
import Interval, { IntervalType } from 'types/Interval'
import Schedule from 'types/Schedule'
import ScheduleEvent from 'types/ScheduleEvent'
import svgUtils from './EffectivenessGraphSvgUtils'
import EffectivenessGraphTimeline from './EffectivenessGraphTimeline'
import * as graphTimeUtils from './EffectivenessGraphTimeUtils'
import {
    DashboardInfo,
    EventEditCallback,
    EventHoverCallback,
    GraphBackgroundColor,
    GraphBackgroundColorLevel,
    GraphBackgroundRectangleOptions,
    GraphConfig,
    GraphProps,
    Scales,
    XScale,
    YScale,
    ZoombarOptions,
    ZoomDetails,
} from './EffectivenessGraphTypes'
import * as graphUtils from './EffectivenessGraphUtils'
import { getDashboardScaledDetailData } from './EffectivenessGraphUtils'
import EffectivenessGraphZoombar from './EffectivenessGraphZoombar'
import './graph.css'
import InspectorLines from './InspectorLines'

export class EffGraph {
    private config: GraphConfig
    private scales: Scales
    private rightAxisYScale: YScale | undefined
    private svg: SVGEL
    private inspectors: InspectorLines
    private isBothMode: boolean

    constructor(
        private schedule: GraphSchedule,
        overallHeight: number,
        isPrintPreview: boolean,
        isReadonly: boolean,
        private hasPinnedDashboard: boolean,
        graphContainer: HTMLDivElement,
        pinnedDashboardContainer: HTMLDivElement,
        leftZoomRatio: number,
        rightZoomRatio: number,
        private zoomCompletedHandler: (zoomDetail: ZoomDetails) => void,
        private eventHoverHandler: EventHoverCallback,
        private editEventHandler: EventEditCallback,
        private showLoading: (loading: boolean) => void,
        private addNewEvent: (scheduleEvent: ScheduleEvent) => void,
        private updateSchedule: (schedule: Schedule) => void,
        private dashboards: DashboardInfo[],
        private addDashboard: (dashboard: DashboardInfo) => void,
        private updateDashboard: (dashboardId: string, detailData: DetailData, updateInspectors: boolean) => void,
        private closeDashboard: (dashboard: DashboardInfo) => void,
        private api: SFApi,
    ) {
        this.isBothMode = schedule.viewSettings.viewMode === ScheduleDetailsViewMode.Both
        const rightMargin = this.calculateRightMargin(schedule.viewSettings.overlays.rightAxis !== null)
        this.applyOptionalPinnedDashboardLayout(
            hasPinnedDashboard,
            graphContainer,
            pinnedDashboardContainer,
            isPrintPreview,
        )
        const graphWidth = this.getSvgContainerWidth(isPrintPreview, graphContainer) - graphUtils.GraphX - rightMargin
        const plotAreaHeight = graphUtils.getPlotAreaHeight(
            isPrintPreview,
            this.isBothMode,
            hasPinnedDashboard,
            graphContainer,
            overallHeight,
        )

        this.config = {
            graphContainer,
            graphWidth,
            rightMargin,
            timelineRowHeight: graphUtils.getKeyItemHeight(this.isBothMode),
            timelineHeight: graphUtils.getKeyHeight(this.isBothMode),
            isPrintPreview,
            isReadonly,
            rightAxisSelections: schedule.viewSettings.overlays,
            calculatedPlotAreaHeight: plotAreaHeight,
            overallGraphHeight: overallHeight,
            leftZoomRatio,
            rightZoomRatio,
            graphPalette: schedule.colorPalettes.effectiveness,
        }

        this.scales = {
            xScale: this.setXScale(),
            yScale: this.setYScale(),
        }

        this.updateXScale(leftZoomRatio, rightZoomRatio)

        this.svg = this.createSvgContainer()

        this.inspectors = new InspectorLines(
            this.getGraphContentHeight(),
            this.scales.xScale,
            addDashboard,
            updateDashboard,
            closeDashboard,
            this.config.isReadonly,
            this.config.isPrintPreview,
        )
    }

    // used in case there is no data to show in the graph
    private static readonly DefaultMinDate = new Date(0)
    private static readonly DefaultMaxDate = new Date()

    private static readonly LabelLeft = 40

    /**
     * Extra space above the zoombar, below the graph
     */
    private static readonly ZoombarTopMargin = 10
    private static readonly XAxisTickmarkYOffsetFromBottomOfKey = 17
    private static readonly DateFormat = moment().locale(navigator.language).localeData().longDateFormat('L')

    private calculateRightMargin = (hasRightAxisEnabled: boolean) => (hasRightAxisEnabled ? 50 : 0)

    private getSvgContainerWidth = (isPrintPreview: boolean, containingElement: HTMLDivElement): number => {
        if (isPrintPreview) {
            return this.hasPinnedDashboard ? graphUtils.PrintDimensionsPinned.width : graphUtils.PrintDimensions.width
        }
        return containingElement.offsetWidth
    }

    private updateYScale = (newGraphHeightIncludingKeyAndXAxis: number, scales: Scales) => {
        const graphHeight = newGraphHeightIncludingKeyAndXAxis
        const plotAreaHeight = graphUtils.getPlotAreaHeight(
            this.config.isPrintPreview,
            this.isBothMode,
            this.hasPinnedDashboard,
            this.config.graphContainer,
            this.config.overallGraphHeight,
        )
        if (Number.isNaN(plotAreaHeight)) {
            throw Error('ContentHeight is NAN')
        }

        scales.yScale.range([plotAreaHeight, 0])

        this.config.calculatedPlotAreaHeight = plotAreaHeight
        this.config.overallGraphHeight = graphHeight
    }

    private getSvgContainerRatioDimensions = (scales: Scales) => {
        const svgContainerHeight = graphUtils.getSvgContainerHeight(
            this.config.isPrintPreview,
            this.isBothMode,
            this.hasPinnedDashboard,
            this.config.graphContainer,
            this.config.overallGraphHeight,
        )
        const width = this.getSvgContainerWidth(this.config.isPrintPreview, this.config.graphContainer)

        const dimensions = {
            height: svgContainerHeight,
            width,
        }

        this.updateYScale(svgContainerHeight, scales)

        return dimensions
    }

    private createSvgContainer = (): SVGEL => {
        const dimensions = this.getSvgContainerRatioDimensions(this.scales)

        this.config.graphContainer.innerHTML = ''
        return d3
            .select(this.config.graphContainer)
            .append('svg')
            .attr('class', 'effectivenessGraph')
            .attr('style', 'background-color: #ffffff')
            .attr('width', dimensions.width)
            .attr('height', dimensions.height + EffGraph.ZoombarTopMargin) // prevent scaling
    }

    private getGradientDefId = (lightType: string, level: string): string => lightType + 'Gradient' + level

    /**
     * Create various definitions used by other functions
     */
    private createSvgDefinitions = () => {
        const defs = this.svg.append('svg:defs')
        const createClipPath = (id: string) => defs.append('svg:clipPath').attr('id', id)

        const thresholds = this.config.graphPalette
        const colorDefs = graphUtils.getGraphEffectivenessBackgroundColors(thresholds).graphBackground
        const clipKey = createClipPath('clipKey')
        const clipKeyLightUnderlay = createClipPath('clipKeyLightUnderlay')
        const clipGraph = createClipPath('clipGraph')
        const clipWaypoints = createClipPath('clipWaypoints')
        const clipDateBar = createClipPath('clipDateBar')

        const createLinearGradient = (lightType: string, level: string) => {
            const colorDefForLightType = colorDefs.filter((x) => x.name === lightType)[0] as GraphBackgroundColor
            const gradientColor = colorDefForLightType.levels.filter(
                (x) => x.name === level,
            )[0] as GraphBackgroundColorLevel

            // const gradientColor = colorDefs[lightType][level];
            const section = defs
                .append('svg:linearGradient')
                .attr('id', this.getGradientDefId(lightType, level))
                .attr('x1', '100%')
                .attr('y1', '100%')
                .attr('x2', '100%')
                .attr('y2', '0%')
                .attr('spreadMethod', 'pad')
                .attr('offset', '0%')

            section
                .append('svg:stop')
                .attr('offset', '0%')
                .attr('stop-color', gradientColor.gradientEndColor)
                .attr('stop-opacity', 1)

            section
                .append('svg:stop')
                .attr('offset', '100%')
                .attr('stop-color', gradientColor.gradientStartColor)
                .attr('stop-opacity', 1)
        }

        clipKeyLightUnderlay
            .append('svg:path')
            .attr('id', 'keyUnderlayClipPath')
            .attr(
                'd',
                'M 0 ' +
                    (this.config.calculatedPlotAreaHeight - 10) +
                    ' h ' +
                    this.config.graphWidth +
                    ' v ' +
                    (graphUtils.getKeyHeight(this.isBothMode) + 5) +
                    ' h ' +
                    -this.config.graphWidth +
                    ' Z',
            )

        clipKey
            .append('svg:path')
            .attr('id', 'keyClipPath')
            .attr(
                'd',
                'M' +
                    graphUtils.GraphX +
                    ' -10H' +
                    (this.config.graphWidth + graphUtils.GraphX) +
                    'V' +
                    (this.config.timelineHeight - 10) +
                    'H' +
                    graphUtils.GraphX +
                    'V-10 Z',
            )

        clipGraph
            .append('svg:path')
            .attr('id', 'graphClipPath')
            .attr('d', 'M0 0H' + this.config.graphWidth + 'V' + this.config.calculatedPlotAreaHeight + 'H0V0 Z')

        clipWaypoints
            .append('svg:path')
            .attr('id', 'waypointsClipPath')
            .attr(
                'd',
                'M' +
                    graphUtils.GraphX +
                    ',-40H' +
                    (this.config.graphWidth + graphUtils.GraphX) +
                    'V' +
                    EffectivenessGraphTimeline.WaypointSize +
                    'H' +
                    graphUtils.GraphX +
                    'V-40 Z',
            )

        clipDateBar
            .append('svg:path')
            .attr('id', 'datebarClipPath')
            .attr(
                'd',
                'M' +
                    0 +
                    ' -' +
                    (graphUtils.GraphY + this.config.calculatedPlotAreaHeight) +
                    'H' +
                    this.config.graphWidth +
                    'V0H0V-' +
                    (graphUtils.GraphY + this.config.calculatedPlotAreaHeight) +
                    ' Z',
            )

        /**
         * create all gradient definitions
         */
        ;['daylight', 'dark', 'twilight'].forEach((lightType) => {
            ;['high', 'medium', 'mediumLow', 'low'] //  these gradient levels must match those in constants.json
                .forEach((level) => {
                    createLinearGradient(lightType, level)
                })
        })
    }

    private createYAxisAndLabels = () => {
        const axis = d3.axisLeft(this.scales.yScale).tickSize(6).ticks(6)

        const group = this.svg
            .append('g')
            .attr('class', 'yAxis')
            .attr('transform', 'translate(' + (graphUtils.GraphX + 1) + ', ' + graphUtils.GraphY + ')')
            .call(axis)

        group
            .append('text')
            .attr('class', 'label graph-effectiveness-label')
            .attr(
                'transform',
                'translate(' + -EffGraph.LabelLeft + ', ' + this.config.calculatedPlotAreaHeight / 2 + ') rotate(-90)',
            )
            .attr('text-anchor', 'middle')
            .text('Effectiveness %')
    }

    /**
     * Create a function that scales from date objects to x-axis pixels on the graph
     */
    private setXScale = (): XScale => {
        const data = this.schedule.detailDataWithTicks.detailData
        const xScale: XScale = d3.scaleUtc().range([0, this.config.graphWidth])
        const min = d3.min(data.map((d) => d.utcTime.getTime())) || EffGraph.DefaultMinDate.getTime()
        const max = d3.max(data.map((d) => d.utcTime.getTime())) || EffGraph.DefaultMaxDate.getTime()
        xScale.domain([min, max])

        return xScale
    }

    /**
     * Create a function that will scale a integer number (0 - 100) to the appropriate pixel in the graph
     */
    private setYScale = (): YScale => {
        const data = this.schedule.detailDataWithTicks.detailData
        const yScale: YScale = d3.scaleLinear().range([this.config.calculatedPlotAreaHeight, 0])
        let min = d3.min(data.map((d) => d.effectiveness))
        // Override calculated min with a fixed value.
        const max = d3.max(data.map((d) => d.effectiveness)) || 100

        // if the data if below {defaultMinimum}, use that, but not less than 20
        const dataMin = Math.max(0, d3.min(data.map((d) => d.effectiveness - 5)) || 60)
        const defaultMinimum = this.isBothMode ? 75 : 60

        min = Math.min(defaultMinimum, dataMin)

        yScale.domain([min, max])

        return yScale
    }

    /**
     * Create vertical lines in the svg in the graph, extending up from the x-axis labels
     */
    private createVerticalHourLines = (group: D3Selection) => {
        group
            .attr('class', 'xAxisMinor js-redraw js-graph-vertical-lines')
            .attr('transform', 'translate(' + graphUtils.GraphX + ', ' + graphUtils.GraphY + ')')
    }

    private createGraphBackgroundColors = (parentGroup: D3Selection) => {
        const plotAreaHeight = this.config.calculatedPlotAreaHeight

        // a default interval needed to show the background; used when no data is provided to the graph
        const defaultInterval = new Interval(EffGraph.DefaultMinDate, EffGraph.DefaultMaxDate, 0, 0, true, 'dark')
        const intervalsToUse: Array<Interval> = this.schedule.lightIntervals.length
            ? this.schedule.lightIntervals
            : [defaultInterval]

        const group = parentGroup
            .append('g')
            .attr('class', 'light js-redraw')
            .attr('transform', 'translate(0,0)')
            .attr('clip-path', 'url(#clipGraph)')

        // draw top area rect
        const lightBar = group.selectAll('rect').data(intervalsToUse).enter()

        const appendRectangle = (graphBackgroundRectangleOptions: GraphBackgroundRectangleOptions) => {
            const yPosition = graphBackgroundRectangleOptions.yFunction()
            let height = graphBackgroundRectangleOptions.heightFunction()

            if (yPosition > plotAreaHeight) {
                // there's no space in the graph for this rectangle, so leave it out entirely
                return
            }

            if (yPosition + height > plotAreaHeight) {
                // the default calculated height is too large for the remaining
                // space in the graph, so truncate it.
                height = plotAreaHeight - yPosition
            }

            lightBar
                .append('rect')
                .attr('class', (d) => d.getDataType() + 'BarRect keyRect')
                .attr('x', (d) => this.scales.xScale(d.getStartMs()))
                .attr('y', yPosition)
                .attr('width', (d) => this.scales.xScale(d.getEndMs()) - this.scales.xScale(d.getStartMs()))
                .attr('height', height)
                .attr(
                    'fill',
                    (d) =>
                        'url(#' + this.getGradientDefId(d.getDataType(), graphBackgroundRectangleOptions.level) + ')',
                )
        }

        const backgroundRectangleGeneratorOptions = graphUtils.createGraphBackgroundColorConfigurations(
            this.scales.yScale,
            this.config.graphPalette,
        )

        backgroundRectangleGeneratorOptions.forEach((options) => {
            appendRectangle(options)
        })
    }

    /**
     * Generate the graph standard deviation area
     */
    private createStandardDeviationArea = (group: D3Selection) => {
        const area = d3
            .area<DetailData>()
            .x((d) => this.scales.xScale(d.utcTime.getTime()))
            .y0((d) => this.scales.yScale(d.lowStandardDeviation))
            .y1((d) => this.scales.yScale(d.highStandardDeviation))
            .curve(d3.curveLinear) // Using curveLinear

        const data = this.schedule.detailDataWithTicks.detailData
        return group
            .append('path')
            .data([data])
            .attr('d', area)
            .attr('opacity', 0.25)
            .attr('fill', 'grey')
            .attr('stroke', 'black')
            .attr('pointer-events', 'none')
    }

    private setRightYScale = (rightAxis: RightAxisType) => {
        this.rightAxisYScale = this.scales.yScale.copy()
        const rightAxisCopy = graphUtils.rightAxes.find((x) => x.type === rightAxis)!
        if (rightAxis === 'LapseIndex') {
            const lapseIndexValues = this.schedule.detailDataWithTicks.detailData.map((detail) => detail.lapseIndex)
            const minLapseIndexValue = d3.min(lapseIndexValues)!
            if (minLapseIndexValue < 0) {
                // lapse index can go below 0 if eff is above 100%, so scale automatically
                rightAxisCopy.scale![0] = minLapseIndexValue
            }
        }
        if (rightAxisCopy && rightAxisCopy.scale) {
            this.rightAxisYScale.domain(rightAxisCopy.scale)
        }
    }

    private createRedLineSeries = (rightAxis: RightAxisType, group: D3Selection) => {
        const getYScaleProperty = (d: DetailData) => {
            if (rightAxis === 'SleepReservoir') return d.sleepReservoir
            if (rightAxis === 'CircadianPhase') return d.relativePhase
            if (rightAxis === 'GoalPhase') return d.relativeGoalPhase
            if (rightAxis === 'OutOfPhase') return d.outOfPhaseHrs
            if (rightAxis === 'SleepIntensity') return d.sleepIntensity
            if (rightAxis === 'LapseIndex') return d.lapseIndex
            if (rightAxis === 'Workload') return d.workload
            if (rightAxis === 'BAC') return 0
            throw Error(`Unknown right-axis:${rightAxis}`)
        }

        const lineFn = d3
            .line<DetailData>()
            .curve(d3.curveLinear)
            .x((d) => {
                return this.scales.xScale(d.utcTime.getTime())
            })
            .y((d) => {
                return this.rightAxisYScale!(getYScaleProperty(d))
            })

        const data = this.schedule.detailDataWithTicks.detailData
        group.append('path').data([data]).attr('class', `redLine js-redline-${rightAxis} js-redline`).attr('d', lineFn)
    }

    private createRightAxisLines = (group: D3Selection) => {
        const rightAxis = this.config.rightAxisSelections.rightAxis || null
        if (rightAxis === null) {
            return
        }

        this.setRightYScale(rightAxis)
        this.createRedLineSeries(rightAxis, group)
        this.createRightYAxisAndLabels(rightAxis)
    }

    /**
     * Draw in the Criterion line and 100% line (and any other similar lines)
     * @param config
     * @param group
     * @param data
     * @param scales
     * @param thresholds
     */
    private graphReferenceLines = (group: D3Selection, thresholds: Palette[]) => {
        // include a default threshold for blank graph mode
        const criterionLevel = thresholds.length >= 2 ? thresholds[1].threshold : 77
        if (this.config.calculatedPlotAreaHeight > this.scales.yScale(criterionLevel)) {
            const data = this.schedule.detailDataWithTicks.detailData
            // x-points from minimum x to maximum x (to stretch line across the graph)
            const fullWidthLine: number[] = [
                d3.min(data, (d) => {
                    return d.utcTime.getTime() || 0
                }) || 0,

                d3.max(data, (d) => {
                    return d.utcTime.getTime()
                }) || 0,
            ]

            // line starting point
            const criterionTargetLine = d3
                .line<number>()
                .curve(d3.curveLinear)
                .x((d) => this.scales.xScale(d))
                .y(() => this.scales.yScale(criterionLevel))

            const oneHundredPercentLine = d3
                .line<number>()
                .curve(d3.curveLinear)
                .x((d) => this.scales.xScale(d))
                .y(() => this.scales.yScale(100))

            group.append('path').data([fullWidthLine]).attr('class', 'targetLine').attr('d', oneHundredPercentLine)
            group.append('path').data([fullWidthLine]).attr('class', 'targetLine').attr('d', criterionTargetLine)

            // when sleep reservoir is shown, also drag a target line
            if (this.schedule.viewSettings.overlays.rightAxis === 'SleepReservoir') {
                if (!this.rightAxisYScale) {
                    throw Error('Must set rightAxisYScale before drawing reference lines')
                }

                const sleepReservoirTargetLine = d3
                    .line<number>()
                    .curve(d3.curveLinear)
                    .x((d) => this.scales.xScale(d))
                    .y(() => this.rightAxisYScale!(75))

                group
                    .append('path')
                    .data([fullWidthLine])
                    .attr('class', 'redLineOrigin')
                    .attr('d', sleepReservoirTargetLine)
            }
        }
    }

    createMouseOverlayArea = (group: D3Selection) => {
        const lowAreaDomainBottom = 0
        const data = this.schedule.detailDataWithTicks.detailData
        const mouseOverlayArea = d3
            .area<DetailData>()
            .curve(d3.curveBasis)
            .x((d) => {
                return this.scales.xScale(d.utcTime.getTime()) > 0 ? this.scales.xScale(d.utcTime.getTime()) : 0
            }) // overay should always positive numbers
            .y0(() => {
                return this.scales.yScale(lowAreaDomainBottom) > this.config.calculatedPlotAreaHeight
                    ? this.config.calculatedPlotAreaHeight
                    : this.scales.yScale(lowAreaDomainBottom)
            })
            .y1(() => {
                return 0
            })
        // having these drag handlers setup avoids adding dashboards to the
        // graph if the user intended to drag an existing dashboard inspector
        const dragGraphHandler = d3
            .drag<SVGPathElement, DetailData[]>()
            .subject(Object)
            .on('drag', () => {
                // no-op
            })
            .on('end', () => {
                // no-op
            })

        // group for containing inspector lines
        const inspectorLineGroup = this.svg
            .append('g')
            .attr('transform', 'translate(' + graphUtils.GraphX + ', ' + graphUtils.GraphY + ')')

        const scaledData = getDashboardScaledDetailData(
            this.schedule.detailDataWithTicks.detailData,
            this.scales.xScale,
        )

        // hook up the click to add dashboard
        group
            .append<SVGPathElement>('path')
            .data([data])
            .attr('class', 'js-graph-rect')
            .attr('opacity', 0)
            .attr('d', mouseOverlayArea)
            .on('click', (e: PointerEvent) => {
                if (
                    this.schedule.viewSettings.viewMode !== ScheduleDetailsViewMode.Graph ||
                    this.config.isPrintPreview
                ) {
                    // inspector/dashboard is only for full Graph view
                    return
                }
                this.inspectors.createDashboardAtClickLocation(e, this.dashboards, scaledData)
            })
            .call(dragGraphHandler)

        if (!this.isBothMode) {
            this.dashboards.forEach((dashboard) => {
                this.inspectors.createInspectorLine(inspectorLineGroup, dashboard, scaledData)
            })
        }
    }

    /**
     * Create the data content in the graph including the effectiveness plot, reference lines,
     * the right-axis plot, etc.
     */
    private createInGraphContent = () => {
        const effThresholds = this.config.graphPalette
        const group = this.svg
            .append('g')
            .attr('class', 'myGraph js-redraw js-my-graph')
            .attr('transform', 'translate(' + graphUtils.GraphX + ', ' + graphUtils.GraphY + ')')
            .attr('clip-path', 'url(#clipGraph)')

        this.createRightAxisLines(group)
        this.graphReferenceLines(group, effThresholds)
        svgUtils.createGraphLineWithAllSections(group, this.schedule.detailDataWithTicks.detailData, this.scales)
        this.createMouseOverlayArea(group)

        if (this.config.rightAxisSelections.standardDeviation) {
            this.createStandardDeviationArea(group)
        }

        // create a black border around the main content area
        group
            .append('rect')
            .attr('width', this.config.graphWidth)
            .attr('height', this.config.calculatedPlotAreaHeight)
            .attr('class', 'graphBorder')
    }

    /**
     * Create the labels along the bottom of the graph
     */
    private createXAxisHoursAndLabels = () => {
        const datesAndOffsetsForXAxis = graphTimeUtils.getDatesAndOffsetsForXAxis(
            this.schedule.detailDataWithTicks,
            this.schedule.viewSettings.timeMode,
            this.config,
            this.scales.xScale,
        )
        const xAxisDates = datesAndOffsetsForXAxis.map((item) => item.time)

        const hourAxis = d3
            .axisBottom<Date>(this.scales.xScale)
            .tickFormat(d3.timeFormat(''))
            .tickSize(2)
            .tickValues(xAxisDates)

        this.svg
            .append('g')
            .attr('class', 'xAxisMinor axisHidden js-redraw')
            .attr(
                'transform',
                'translate(' +
                    graphUtils.GraphX +
                    ', ' +
                    (this.config.calculatedPlotAreaHeight +
                        this.config.timelineHeight +
                        EffGraph.XAxisTickmarkYOffsetFromBottomOfKey) +
                    ')',
            )
            .call(hourAxis)
            .selectAll('text')
            .selectAll('tspan')
            .data((d, i) => {
                const date = d as Date
                const offset = datesAndOffsetsForXAxis[i].utcOffsetMinutes
                const formattedTime = graphTimeUtils.formatDateWithTZOffset(date, 'HH', offset)
                return [formattedTime]
            })
            .enter()
            .append('tspan')
            .text(String)

        // Time reference label
        const labelXOffset = -60
        const labelYOffset = 15 - (this.schedule.viewSettings.viewMode === ScheduleDetailsViewMode.Both ? 3 : 0)

        this.svg
            .append('g')
            .attr('class', 'xAxisMinor js-redraw')
            .attr(
                'transform',
                'translate(' +
                    graphUtils.GraphX +
                    ', ' +
                    (this.config.calculatedPlotAreaHeight +
                        this.config.timelineHeight +
                        EffGraph.XAxisTickmarkYOffsetFromBottomOfKey) +
                    ')',
            )
            .append('text')
            .attr('class', 'xAxisMinor')
            .attr('x', labelXOffset)
            .attr('y', labelYOffset)
            .text(TimeModeEnum[this.schedule.viewSettings.timeMode])
    }
    /**
     * Create the dates along the top of the graph
     */
    private createTopAxisDates = () => {
        const startDate = this.scales.xScale.domain()[0]
        const endDate = this.scales.xScale.domain()[1]
        const midnightDatesWithOffsets = graphTimeUtils.getGraphMidnightDates(
            this.schedule.detailDataWithTicks.detailData,
            startDate,
            endDate,
            this.schedule.viewSettings.timeMode,
        )

        // reduce the number of midnights by half if (graph Width / num of midnights) is
        // less than the approx. width of day will appear on the graph
        if (this.config.graphWidth / midnightDatesWithOffsets.length < 50) {
            for (let i = 0; i < midnightDatesWithOffsets.length; i++) {
                midnightDatesWithOffsets.splice(i + 1, 1)
            }
        }

        const dateFormat = dateTimeFormatting.getAppDateFormatNoYear()

        const dayAxis = d3
            .axisTop<Date>(this.scales.xScale)
            .tickFormat(d3.timeFormat(''))
            .tickValues(midnightDatesWithOffsets.map((x) => x.time))
            .tickSize(graphUtils.GraphY + this.config.calculatedPlotAreaHeight)

        this.svg
            .append('g')
            .attr('class', 'xAxisDate axisHidden js-redraw')
            .attr(
                'transform',
                'translate(' +
                    graphUtils.GraphX +
                    ', ' +
                    (graphUtils.GraphY + this.config.calculatedPlotAreaHeight) +
                    ')',
            )
            .attr('clip-path', 'url(#clipDateBar)')
            .call(dayAxis)
            .selectAll('text')
            .style('text-anchor', 'start')
            .attr('transform', 'translate(2,' + graphUtils.GraphY + ')')
            .selectAll('tspan')
            .data((d) => {
                // not sure what is missing such that I have to cast here.
                const date = d as Date
                const utcOffsetMinutes = midnightDatesWithOffsets.find(
                    (x) => x.time.getTime() === date.getTime(),
                )!.utcOffsetMinutes
                const formattedDateString = graphTimeUtils.formatDateWithTZOffset(date, dateFormat, utcOffsetMinutes)
                return [formattedDateString]
            })
            .enter()
            .append('tspan')
            .attr('x', 0)
            .attr('dy', (d, i) => i + 'em') // either 0 or 1em for the dy
            .text(String)

        this.svg
            .append('rect')
            .attr('class', 'js-dateRect')
            .attr('x', graphUtils.GraphX)
            .attr('y', graphUtils.GraphY - graphUtils.GraphY)
            .attr('width', this.config.graphWidth)
            .attr('height', graphUtils.GraphY)
            .attr('stroke', 'lightgrey')
            .attr('fill', 'none')
    }

    /**
     * Create the SVG zoombar below the graph.
     */
    private createZoombar = (zoomChanged: (d: ZoomDetails) => void) => {
        const svgAbsWidth = this.getSvgContainerWidth(this.config.isPrintPreview, this.config.graphContainer)
        const zoombarOptions: ZoombarOptions = {
            zoombarRelativeWidth: this.config.graphWidth,
            fullSvgRelativeWidth: this.getSvgContainerWidth(this.config.isPrintPreview, this.config.graphContainer),
            graphX: graphUtils.GraphX,
            graphHeight: this.config.overallGraphHeight,
            zoomBarHeight: graphUtils.getZoomBarHeight(this.isBothMode),
            zoombarTopMargin: EffGraph.ZoombarTopMargin,
            detailData: this.schedule.detailDataWithTicks.detailData,
            svgEl: this.svg,
            leftSliderPositionTime: null,
            rightSliderPositionTime: null,
            zoomLeftRatio: this.config.leftZoomRatio ?? 0,
            zoomRightRatio: this.config.rightZoomRatio ?? 1,
            fullSvgAbsoluteWidth: svgAbsWidth,
            effectivenessPalette: this.config.graphPalette,
            timeMode: this.schedule.viewSettings.timeMode,
            updateGraphCallback: zoomChanged,
        }
        const zoombar = new EffectivenessGraphZoombar(zoombarOptions)
        zoombar.render()
    }

    private getGraphContentHeight = () => {
        return this.scales.yScale(this.scales.yScale.domain()[0]) + graphUtils.getKeyHeight(this.isBothMode)
    }

    /**
     * Create a vertical at the start or end of DST
     */
    private createVerticalDSTMarkerLine = (group: D3Selection) => {
        const dstChangeDates = graphUtils.findDstChangeTimes(this.schedule.detailDataWithTicks.detailData)
        dstChangeDates.forEach((d) => {
            group
                .append('line')
                .attr('class', 'js-graph-vertical-dst-lines')
                .attr('x1', this.scales.xScale(d.getTime()))
                .attr('x2', this.scales.xScale(d.getTime()))
                .attr('y1', 0)
                .attr('y2', this.getGraphContentHeight())
        })
    }

    private createGraphContent = (zoomChanged: (d: ZoomDetails) => void) => {
        // clear any old svg if we are re-rendering based on zoom, etc.
        document.querySelectorAll('.js-redraw').forEach((x) => x.remove())

        const xAxisGroup: D3Selection = this.svg.append('g')

        this.createGraphBackgroundColors(xAxisGroup)
        this.createVerticalHourLines(xAxisGroup)
        this.createTopAxisDates()
        this.createInGraphContent()
        this.createXAxisHoursAndLabels()
        const timeline = new EffectivenessGraphTimeline(
            this.svg,
            xAxisGroup,
            this.config,
            this.schedule,
            this.scales,
            this.eventHoverHandler,
            this.editEventHandler,
            this.updateSchedule,
            this.showLoading,
            this.addNewEvent,
            this.api,
        )
        timeline.render()

        // render things after the timeline that need to be in front of the timeline
        this.createVerticalDSTMarkerLine(xAxisGroup)

        if (this.schedule.detailDataWithTicks.detailData.length && !this.config.isPrintPreview) {
            this.createZoombar(zoomChanged)
        }
    }

    private createRightYAxisAndLabels = (rightAxis: RightAxisType) => {
        const axis = d3.axisRight(this.rightAxisYScale!).tickSize(6).ticks(6)

        const rightAxisConfig = graphUtils.rightAxes.find((x) => x.type === rightAxis)! // _.clone(that.graphConfig.rightAxes[index])
        if (rightAxisConfig.tickValues) {
            axis.tickValues(rightAxisConfig.tickValues)
        }

        // better to put this into rightAxes object
        if (rightAxis === 'BAC') {
            axis.tickValues([70, 77]).tickFormat((d) => {
                if (d === 70) {
                    return '.08'
                }
                if (d === 77) {
                    return '.05'
                }
                return ''
            })
        }

        const group = this.svg
            .append('g')
            .attr('class', 'yAxis yRightAxis js-right-y-axis-redraw')
            .attr(
                'transform',
                'translate(' + (graphUtils.GraphX + this.config.graphWidth) + ', ' + graphUtils.GraphY + ')',
            )
            .call(axis)

        group
            .append('text')
            .attr('class', 'yRightAxis')
            .attr('transform', 'translate(35, ' + this.config.calculatedPlotAreaHeight / 2 + ') rotate(90)')
            .attr('text-anchor', 'middle')
            .text(rightAxisConfig.label)
    }

    private updateXScale = (leftZoomRatio: number, rightZoomRatio: number) => {
        // calculate the position in the graph based on the ratio in the zoom bar
        const leftZoomPosition = this.config.graphWidth * leftZoomRatio // x position in graph
        const rightZoomPosition = this.config.graphWidth * rightZoomRatio

        // convert the left and right pixal positions to values min and max values in the data
        const leftLimit = this.scales.xScale.invert(leftZoomPosition)
        const rightLimit = this.scales.xScale.invert(rightZoomPosition)

        // reset the scale to match new visable area
        this.scales.xScale.domain([leftLimit.getTime(), rightLimit.getTime()])
    }

    /**
     * Set the graph width appropriately for pinned vs non-pinned.
     */
    private applyOptionalPinnedDashboardLayout = (
        hasPinnedDashboard: boolean,
        graphContainer: HTMLDivElement,
        pinnedDashboardContainer: HTMLDivElement,
        isPrintPreview: boolean,
    ) => {
        let graphFloat = 'none'
        let graphWidth = window.innerWidth - 30

        if (hasPinnedDashboard) {
            graphFloat = 'left'
            graphWidth -= graphUtils.PinnedDashboardWidth // reduce width for pinned dashboard
            graphWidth -= 5 // reduce width for additional buffer

            pinnedDashboardContainer.style.width = `${graphUtils.PinnedDashboardWidth}px`
            pinnedDashboardContainer.style.float = 'right'
        }

        if (isPrintPreview) {
            // SVG is fixed width, set container width to minimal
            graphWidth = 0
        }

        graphContainer.style.width = `${graphWidth}px`
        graphContainer.style.float = graphFloat
    }

    render = () => {
        this.createSvgDefinitions()
        this.createYAxisAndLabels()

        const zoomCompleted = (zoomDetail: ZoomDetails) => {
            this.zoomCompletedHandler(zoomDetail)
        }
        this.createGraphContent(zoomCompleted)
    }
}

/**
 * Derived from the detail data, these "light intervals" are displayed along the bottom of the graph as day, twilight, dark.
 * They are created as EventModels, so they have the same methods available and can be used similarily.
 */
export const createLightIntervals = (detailItems: DetailData[]): Interval[] => {
    let detailIdx = 0
    let prevDetail
    const result: Interval[] = []
    let uniqueIdx = 0

    const getLightState = (detail: DetailData): IntervalType => {
        const lightPhaseMap: {
            [key: number]: IntervalType
        } = {
            0: 'dark',
            0.5: 'twilight',
            1: 'daylight',
        }
        return lightPhaseMap[detail.lightPhase]
    }

    const getDuration = (interval: Interval) => {
        return ((interval.end?.getTime() ?? 0) - interval.start.getTime()) / (1000 * 60)
    }

    let lightIntervalItem: Interval | null = null
    let currentLightState: IntervalType | null = null
    for (detailIdx = 0; detailIdx < detailItems.length; detailIdx++) {
        const detail = detailItems[detailIdx]

        const nextLightState = getLightState(detail)

        if (nextLightState !== currentLightState) {
            // End at the current date/time so light/dark is contiguous
            if (lightIntervalItem) {
                // update item already in the array
                lightIntervalItem.end = detail.utcTime
                lightIntervalItem.tzFromMinutes = detail.tz * 60
                lightIntervalItem.tzToMinutes = detail.tz * 60
                lightIntervalItem.duration = getDuration(lightIntervalItem)
                lightIntervalItem.tzBaseMinutes = detail.tzBase * 60
                lightIntervalItem.uniqueName = `interval_${uniqueIdx++}`
                lightIntervalItem = null
            }

            lightIntervalItem = new Interval(
                detail.utcTime,
                null,
                detail.tz * 60,
                detail.tzBase * 60,
                true,
                nextLightState,
            )
            result.push(lightIntervalItem)
            currentLightState = nextLightState
        }

        prevDetail = detail
    }

    if (lightIntervalItem) {
        lightIntervalItem.end = prevDetail?.utcTime ?? null
        lightIntervalItem.duration = getDuration(lightIntervalItem)
        lightIntervalItem.tzFromMinutes = prevDetail?.tz ?? 0
        lightIntervalItem.tzToMinutes = prevDetail?.tz ?? 0
        lightIntervalItem.tzBaseMinutes = prevDetail?.tzBase ?? 0
        lightIntervalItem.uniqueName = `interval_${uniqueIdx}`
    }

    return result
}

/**
 * Is the given date object's local equivalent (based on offsetHours) a multiple of N hours on the clock.
 * Eg, if N=6, is it 00, 06, 12, 18, in local time?
 */
const isLocalTimeAMultipleOfNHours = (dateObj: Date, offsetHours: number, toleranceMinutes: number, nHours: number) => {
    const toleranceMins = toleranceMinutes || 0
    const millisLocal = dateObj.getTime() + offsetHours * 3600000
    const millisPerDay = 24 * 3600000
    const minutesPerNHours = 60 * nHours
    const minutesThisDay = (millisLocal % millisPerDay) / 60000
    const minutesThisHour = new Date(millisLocal).getMinutes()
    return minutesThisDay % minutesPerNHours <= toleranceMins && minutesThisHour <= toleranceMins
}

export const prepDataForGraph = (detailData: DetailData[]): DetailDataWithTimelineTicks => {
    /**
     * Constructs a date that is N hours later than the given date
     */
    const getDateNHoursLater = (date: Date, nHours: number): Date => new Date(date.getTime() + nHours * 60 * 60 * 1000)

    const values: DetailData[] = []
    const localTick: Date[] = []
    const localOffset: number[] = []
    const baseTick: Date[] = []
    const baseOffset: number[] = []
    let d = new Date(0)
    let dtb = new Date(0)
    detailData.forEach((detail) => {
        const dt = new Date(detail.utcTime)
        values.push(detail)

        // Get a "tick" time for every hour.  At this point in the process we don't know what the zoom
        // level is at, so we need every hour generated just in case they are zoomed in close.
        const hoursMultipleForTicks = 1

        if (isLocalTimeAMultipleOfNHours(dt, detail.tz, 15, hoursMultipleForTicks)) {
            if (dt.getTime() >= d.getTime()) {
                localTick.push(dt)
                localOffset.push(detail.tz)
                const dtHrBegin = new Date(dt.getTime() - dt.getMinutes() * 60000) // want dtb to be beginning of hour with no minutes so dt.getTime() >= d.getTime() doesn't miss creating ticks for whatever timespan sfc-2147
                d = getDateNHoursLater(dtHrBegin, 1)
            }
        }

        if (isLocalTimeAMultipleOfNHours(dt, detail.tzBase, 15, hoursMultipleForTicks)) {
            if (dt.getTime() >= dtb.getTime()) {
                baseTick.push(dt)
                baseOffset.push(detail.tzBase)
                const dtbHrBegin = new Date(dt.getTime() - dt.getMinutes() * 60000) // sfc-2147 same as above
                dtb = getDateNHoursLater(dtbHrBegin, 1)
            }
        }
    })
    return {
        detailData: values,
        localTick,
        localOffset,
        baseTick,
        baseOffset,
    }
}

/**
 * Effectiveness Graph Component
 * @param props
 * @returns
 */
const EffectivenessGraph = memo((props: GraphProps) => {
    const api = globals.getApi()
    const dispatch = useDispatch()

    const memoizedGraphSchedule = useMemo((): GraphSchedule => {
        const result = new GraphSchedule(
            props.schedule.id,
            props.schedule.scenarioId,
            props.schedule.name,
            props.schedule.modified,
            props.schedule.scenarioName,
            props.schedule.scenarioParameters,
            props.schedule.events,
            props.schedule.baseLocation,
            props.schedule.viewSettings,
            props.schedule.detailDataWithTimelineTicks!,
            createLightIntervals(props.schedule.detailDataWithTimelineTicks!.detailData),
            props.schedule.colorPalettes,
        )

        return result
    }, [props.schedule])

    useEffect(() => {
        const effGraph = new EffGraph(
            memoizedGraphSchedule,
            props.height,
            props.isPrintPreview,
            props.isReadonly,
            props.hasPinnedDashboard,
            props.graphContainerEl.current!,
            props.pinnedDashoardContainerEl.current!,
            props.leftZoomRatio,
            props.rightZoomRatio,
            props.updateGraphAfterZoom,
            props.eventHoverHandler,
            props.eventEditHandler,
            (isLoading: boolean) => {
                if (isLoading) {
                    dispatch(globalActions.showLoadingModal())
                } else {
                    dispatch(globalActions.hideLoadingModal())
                }
            },
            props.addNewEvent,
            props.updateSchedule,
            props.dashboards,
            props.addDashboard,
            props.updateDashboardData,
            props.closeDashboard,
            api,
        )
        effGraph.render()
    }, [props, memoizedGraphSchedule, api, dispatch])

    return <></>
})

export default EffectivenessGraph
