import moment from 'moment'
import 'moment/min/locales'

const getAppDateFormat = (): string => {
    return moment().locale(navigator.language).localeData().longDateFormat('L')
}

const getAppDateFormatNoYear = (): string => {
    // there seems to be no way to get a localized date from moment or plain js
    // that doesn't include the year.
    // so, this code figures just removes the year part from the localized format.
    const dateFormatWithoutYear = getAppDateFormat().replaceAll('Y', '')
    const matches = dateFormatWithoutYear.match(/[M|D].*[M|D]/)
    if (!matches) {
        // this shouldn't happen, but just in case, have this default
        return 'MM/DD'
    }
    return matches![0]
}

const getAppTimeFormat = (): string => 'HH:mm'
const getAppDateTimeFormat = (): string => `${getAppDateFormat()} ${getAppTimeFormat()}`
const getKendoDateTimeFormat = (): string => getAppDateTimeFormat().replace('DD', 'dd').replace('YYYY', 'yyyy')

/**
 * Convert the digit to a string that is 2 characters (with leading 0)
 */
const digitTo2CharacterNumString = (digit: number) => (digit < 10 ? `0${digit}` : digit.toString())

const formatDateAsTimeString = (date: Date) =>
    `${digitTo2CharacterNumString(date.getHours())}:${digitTo2CharacterNumString(date.getMinutes())}`

const formatTimeStringAsDate = (time: string) => {
    const [hour, minute] = time.split(':')
    const theDate = new Date()
    theDate.setHours(parseInt(hour))
    theDate.setMinutes(parseInt(minute))
    return theDate
}

const getMinutesOfDayFromTimeString = (time: string) => {
    const date = formatTimeStringAsDate(time)
    return date.getHours() * 60 + date.getMinutes()
}

/**
 *
 * @returns format for kendo grid
 */
const getKendoGridDateFormat = (): string => {
    return `{0: ${getKendoDateTimeFormat()}}`
}

const formatDateTimeShort = (milliseconds: number) => {
    // why kendo and moment don't use the same format??
    const dateTimeFormat = getKendoDateTimeFormat().replace('dd', 'DD').replace('YY', 'yy')
    return moment(milliseconds).format(dateTimeFormat)
}

const formatDateTimeLongLocal = (milliseconds: number, noSeconds?: boolean) => {
    const timeFormat = 'HH:mm'
    const dateFormat = getAppDateFormat()
    const datetimeFormat = `${dateFormat} ${timeFormat}`
    const secondsFormat = noSeconds !== true ? ':ss' : ''
    return moment(milliseconds).format(datetimeFormat + secondsFormat)
}

const formatDurationHoursAndMinutes = (
    durationInMinutes: number,
    options?: { hideZeroMinutes?: boolean; noSpace?: boolean },
): string => {
    if (durationInMinutes <= 120) return `${durationInMinutes}m`

    const [hrs, minutes] = getHoursAndMinutesFromDuration(durationInMinutes)
    if (options?.hideZeroMinutes && minutes === 0) {
        return `${hrs}h`
    }

    const hourMinuteSeparator = options?.noSpace ? '' : ' '
    return `${hrs}h${hourMinuteSeparator}${minutes}m`
}

const getHoursAndMinutesFromDuration = (durationInMinutes: number): [number, number] => {
    return [Math.floor(durationInMinutes / 60), durationInMinutes % 60]
}

const formatHrsDurationHhmm = (hrs: number): string => formatDurationHhmm(hrs * 60)
const formatMinsDurationHhmm = (mins: number): string => formatDurationHhmm(mins)

const formatDurationHhmm = (durationInMinutes: number): string => {
    const prefixSign = durationInMinutes < 0 ? '-' : ''
    const minutesInt = Math.floor(Math.abs(durationInMinutes))
    const [hrs, minutes] = getHoursAndMinutesFromDuration(minutesInt)
    const hrsString = digitTo2CharacterNumString(hrs)
    const minutesString = digitTo2CharacterNumString(minutes)
    return `${prefixSign}${hrsString}:${minutesString}`
}

/**
 * Create a date object whose local time is the same value as the given UTC.  This is a way
 * of viewing a UTC time without having the browser apply an offset to it.
 * @param utcTimeMs
 * @param utcOffsetMinutes
 * @returns
 */
const getLocalizedTime = (utcTimeMs: number, utcOffsetMinutes: number): Date => {
    const utcDate = moment(new Date(utcTimeMs)).add(utcOffsetMinutes, 'm').toDate()
    const utcDateString = utcDate.toISOString()
    const parts = utcDateString.split('T')
    const datePart = parts[0]
    const dateParts = datePart.split('-')
    const year = parseInt(dateParts[0])
    const month = parseInt(dateParts[1])
    const day = parseInt(dateParts[2])

    const timePart = parts[1]
    const timeParts = timePart.split(':')
    const hour = parseInt(timeParts[0])
    const minute = parseInt(timeParts[1])
    const secondsAndMs = timeParts[2]
    const seconds = parseInt(secondsAndMs.split('.')[0])
    const localizedDate = new Date(year, month - 1, day, hour, minute, seconds)
    return localizedDate
}

/**
 * Get number of milliseconds from this date until the local date (given the provided offset) is at the nearest midnight
 * @param date
 * @param offsetHours
 * @returns
 */
const getMsUntilLocalMidnight = (date: Date, utcOffsetMinutes: number): number => {
    const millisLocal = date.getTime() + utcOffsetMinutes * 60000
    const millisPerDay = 24 * 3600000
    const millis12Hour = 12 * 3600000
    const millisLocalThisDay = millisLocal % millisPerDay
    const millisFromMidnight =
        millisLocalThisDay < millis12Hour ? -1 * millisLocalThisDay : millisPerDay - millisLocalThisDay
    return millisFromMidnight
}

const convertDateStringsToDates = (input: string): Date => {
    const dateFormatRegex = /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})/
    let formattedTime = input.match(dateFormatRegex)![0]
    formattedTime += 'Z'
    return new Date(formattedTime)
}

const formatHhmmssToHhmm = (input: string): string => {
    if (input.length === 8) {
        // assuming this is hh:mm:ss format,
        // so change to hh:mm
        return input.substr(0, 5)
    }
    return input
}

export default {
    formatHhmmssToHhmm,
    convertDateStringsToDates,
    getLocalizedTime,
    getMsUntilLocalMidnight,
    getAppDateFormat,
    getAppDateFormatNoYear,
    getAppDateTimeFormat,
    getAppTimeFormat,
    getKendoGridDateFormat,
    getKendoDateTimeFormat,
    formatDateTimeLongLocal,
    formatDateTimeShort,
    formatDurationHhmm,
    formatHrsDurationHhmm,
    formatMinsDurationHhmm,
    formatDurationHoursAndMinutes,
    formatDateAsTimeString,
    formatTimeStringAsDate,
    getMinutesOfDayFromTimeString,
}
