import { FormEvent, useContext, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import globals from 'services/global/globals'
import { handleApiError, showResponseMessage } from 'services/utilities/toastrUtils'
import { globalActions } from 'store/globalStore'
import ScheduleDetailsContext from 'store/scheduleDetailsContext'
import { RootState } from 'store/store'
import MetaData from 'types/Metadata'
import Schedule from 'types/Schedule'
import { TagKeyValue } from 'types/ScheduleEvent'
import ButtonCustom from 'views/Common/Buttons/ButtonCustom'
import IconButtonAdd from 'views/Common/Buttons/IconButtonAdd'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'
import ModalWrapper from 'views/Common/GenericDialogs/ModalWrapper'
import StationAutoSuggestInput from 'views/Common/Inputs/StationAutoSuggestInput'
import FormLabelCustom from 'views/Common/Widget/FormLabelCustom'
import TagsTable from 'views/Common/Widget/TagsTable'

type DialogProps = {
    schedule: Schedule
    readonly: boolean
    closeCallback: (status: DialogResultEnum) => void
}

const EditScheduleDialogContent = ({ schedule, readonly, closeCallback }: DialogProps) => {
    const scheduleDetailsContext = useContext(ScheduleDetailsContext)
    const [scheduleName, setScheduleName] = useState<string>(schedule.name)
    const [scheduleBase, setScheduleBase] = useState<string>(schedule.baseLocation)
    const [scheduleTags, setScheduleTags] = useState<TagKeyValue[]>(schedule.tagCollection)
    const [validatedForm, setValidatedForm] = useState(false)
    const metadata = useSelector<RootState, MetaData>((x) => x.app.metadata!)
    const dispatch = useDispatch()
    const api = globals.getApi()

    const areTagsInValid = () => {
        const anyNoNameTags = scheduleTags.filter((x) => x.name === '').length > 0
        const hasDuplicates = scheduleTags.length !== new Set(scheduleTags.map((x) => x.name)).size
        return anyNoNameTags || hasDuplicates
    }

    const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
        // prevent usual form submission
        event.preventDefault()
        event.stopPropagation()

        const form = event.target as HTMLFormElement

        let invalid = false
        if (form.checkValidity() === false) {
            setValidatedForm(true)
            invalid = true
        }

        if (areTagsInValid()) {
            setValidatedForm(true)
            invalid = true
        }

        if (invalid) {
            // keep the form open, let the user fix the issues
            return
        }

        if (form.checkValidity() === false) {
            setValidatedForm(true)
            // keep the form open, let the user fix the issues
            return
        }

        try {
            dispatch(globalActions.showLoadingModal())
            const [updatedSchedule, message] = await api.saveScheduleSettingsForAnalysis(
                schedule.id,
                scheduleName,
                scheduleBase,
                scheduleTags,
            )
            scheduleDetailsContext.setSchedule(updatedSchedule)
            showResponseMessage(message)
            closeCallback(DialogResultEnum.Completed)
        } catch (err: any) {
            handleApiError(err)
        } finally {
            dispatch(globalActions.hideLoadingModal())
        }
    }

    return (
        <>
            <Form noValidate validated={validatedForm} onSubmit={submitHandler}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Schedule Properties</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <FormLabelCustom htmlFor="txtScheduleName">Schedule Name</FormLabelCustom>
                        <Form.Control
                            id="txtScheduleName"
                            disabled={readonly}
                            name="scheduleName"
                            type="text"
                            placeholder="Provide a unique schedule name"
                            value={scheduleName}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setScheduleName(e.target.value)}
                            required
                        />
                        <Form.Control.Feedback type="invalid">Please enter a schedule name</Form.Control.Feedback>
                        <Form.Text className="text-muted">{metadata.scheduleNameCaption ?? ''}</Form.Text>
                    </Form.Group>

                    <Form.Group>
                        <FormLabelCustom htmlFor="txtBaseStation">Base Station</FormLabelCustom>
                        <StationAutoSuggestInput
                            id="txtBaseStation"
                            disabled={readonly}
                            name="baseStation"
                            placeholder="Start typing to search base stations"
                            value={scheduleBase}
                            isInvalidMessage="Please provide a valid base station"
                            onChange={setScheduleBase}
                        />
                    </Form.Group>

                    <Form.Group>
                        <FormLabelCustom>Tags</FormLabelCustom>
                        {!readonly && (
                            <IconButtonAdd
                                style={{ marginLeft: '5px' }}
                                onClick={() => {
                                    setScheduleTags((previousTags: TagKeyValue[]): TagKeyValue[] => {
                                        const tags = [...previousTags]
                                        const previousTagIds = [...previousTags.map((x) => x.id)]
                                        const id = previousTagIds.length === 0 ? 0 : Math.min(...previousTagIds) - 1
                                        tags.push({
                                            id,
                                            name: '',
                                            value: '',
                                            index: 0,
                                        })
                                        return tags
                                    })
                                }}
                            />
                        )}
                        <TagsTable
                            readonly={readonly}
                            allTagNames={schedule.allScheduleTagNames}
                            showValidationErrors={validatedForm}
                            tags={scheduleTags}
                            tagRemoved={(id: number) => {
                                setScheduleTags((previousTags) => {
                                    return [...previousTags].filter((x) => x.id !== id)
                                })
                            }}
                            tagChanged={(id: number, name: string, value: string) => {
                                setScheduleTags((previousTags) => {
                                    const tags = [...previousTags]
                                    const tagToUpdate = tags.find((x) => x.id === id)!
                                    tagToUpdate.name = name
                                    tagToUpdate.value = value
                                    return tags
                                })
                            }}
                        />
                    </Form.Group>
                </Modal.Body>

                <Modal.Footer>
                    <ButtonCustom disabled={readonly} isLarge variant="primary" type="submit">
                        OK
                    </ButtonCustom>
                    <ButtonCustom isLarge variant="secondary" onClick={() => closeCallback(DialogResultEnum.Cancelled)}>
                        Cancel
                    </ButtonCustom>
                </Modal.Footer>
            </Form>
        </>
    )
}

const EditScheduleDialog = (props: DialogProps) => {
    return (
        <ModalWrapper closeCallback={() => props.closeCallback(DialogResultEnum.Cancelled)}>
            <EditScheduleDialogContent {...props} />
        </ModalWrapper>
    )
}

export default EditScheduleDialog
