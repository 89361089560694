/**
 * Responsible for talking to the back-end API (web or SFC)
 */

import SessionControl from 'services/axios/SessionControl'
import { AuthToken } from 'types/AuthToken'
import { EventFilterConfig } from 'types/EventFilterConfig'
import {
    AccountLicense,
    FileProcessing,
    NewScheduleDefaults,
    parseFileProcessing,
    parseNewScheduleDefaults,
    parseUser,
    ScheduleDetailsViewMode,
    SleepQualityEnum,
    TimeModeEnum,
    User,
    UserEditable,
    ViewSettings,
} from 'types/interfaces'
import MetaData, { parseMetadata } from 'types/Metadata'
import { ReportItemDefinitionDataType } from 'types/ReportingMetadata'
import { ResponseMessage } from 'types/ResponseMessage'
import Scenario, { parseScenario, parseScenarioParameters, ScenarioParameters } from 'types/Scenario'
import Schedule, {
    CreateEventsSchedule,
    CreateUpdateShiftsSchedule,
    parseSchedule,
    ScheduleWithEvents,
} from 'types/Schedule'
import ScheduleEvent, { ScheduleEventRecord, TagKeyValue } from 'types/ScheduleEvent'
import { ShareType, ShareUsersConfiguration } from 'types/ShareUsersConfiguration'
import { parseShift, PatternsShifts } from 'types/Shifts'
import { UpdateDutyRequest } from 'types/UpdateDutyRequest'
import { UpdateDutyTimeRequest } from 'types/UpdateDutyTimeRequest'
import { SFC } from '../../types/SFC'
import Axios from '../axios/axios-sfc'
import * as cookies from '../cookies/cookies'
import SFProcessingApi from './sfProcessingApi'
import SFReportingApi from './sfReportingApi'
import SFStationsApi from './sfStationsApi'

const convertEventForServer = (eventRecord: ScheduleEventRecord): any => {
    const getSleepQualityStringOrNull = (value: SleepQualityEnum | null): string | null => {
        if (!value) {
            return null
        }

        return SleepQualityEnum[value].toString()
    }

    return {
        ...eventRecord,
        quality: getSleepQualityStringOrNull(eventRecord.quality),
        plannedWorkSleepQuality: getSleepQualityStringOrNull(eventRecord.plannedWorkSleepQuality),
    }
}

const convertScheduleForServer = (schedule: ScheduleWithEvents): any => {
    // this gets rid of some content from the schedule object
    // filter out autosleep, duty rollupts, etc.
    const scheduleForServer = { ...schedule }
    scheduleForServer.events = [...scheduleForServer.events]
        .filter((x) => !x.isGeneratedEvent())
        .map(convertEventForServer)
    return scheduleForServer
}

type UpdatePatternsShifts = Omit<PatternsShifts, 'shiftTypeList'>

class SFApi {
    private readonly axios: Axios
    private readonly reportingApi: SFReportingApi
    private readonly stationsApi: SFStationsApi
    private readonly processingApi: SFProcessingApi
    constructor(private sessionControl: SessionControl) {
        this.axios = new Axios(sessionControl)
        this.reportingApi = new SFReportingApi(this.axios)
        this.stationsApi = new SFStationsApi(this.axios)
        this.processingApi = new SFProcessingApi(this.axios)
    }

    getReportingApi = () => this.reportingApi
    getStationsApi = () => this.stationsApi
    getProcessingApi = () => this.processingApi

    private getDesktopApi = (): SFC => (window as any).safteFastConsole as SFC

    notifyDesktopLoadingComplete = () => this.getDesktopApi().notify?.('StartApplication', 'complete', 'schedule')
    notifyDesktopCancel = () => this.getDesktopApi().cancel?.()
    launchHelpForm = () => this.getDesktopApi().launchHelp?.()
    launchScheduleDetails = (scheduleId: number) => this.getDesktopApi().launchScheduleDetailsEditor?.(scheduleId)

    updateUser = async (user: UserEditable): Promise<User> => {
        const data = (await this.axios.put('/Api/Users/', user)).data
        return parseUser(data)
    }

    createUser = async (user: UserEditable): Promise<User> => {
        const data = (await this.axios.post('/Api/Users/', user)).data
        return parseUser(data)
    }

    passwordResetRequest = async (loginId: string): Promise<void> => {
        await this.axios.post('/Api/Users/PasswordResetRequest', { loginId })
    }

    validatePasswordResetToken = async (token: string): Promise<void> => {
        await this.axios.get(`/Api/Users/PasswordReset?tokenValue=${token}`)
    }

    setNewPassword = async (token: string, password1: string, password2: string): Promise<void> => {
        const formData = new FormData()
        formData.append('token', token)
        formData.append('pass1', password1)
        formData.append('pass2', password2)
        await this.axios.post('/Api/Users/PasswordReset', formData)
    }

    /**
     * Get PatternsShifts for the dialog.
     * @returns
     */
    getPatternsShifts = async (): Promise<PatternsShifts> => {
        const patternsShifts = (await this.axios.get('/Api/Shifts/GetPatternsShifts/')).data as PatternsShifts
        patternsShifts.patterns.forEach((pattern) => {
            pattern.shifts = pattern.shifts.map((shift) => {
                return parseShift(shift)
            })
        })
        patternsShifts.shiftSegments = patternsShifts.shiftSegments.map((shiftSeg) => parseShift(shiftSeg))
        return patternsShifts
    }

    deleteEvent = async (
        schedule: Schedule,
        scheduleEvents: ScheduleEvent[],
    ): Promise<[Schedule, ResponseMessage?]> => {
        const data = (
            await this.axios.post('/Api/Schedules/DeleteEvent', {
                scheduleId: schedule.id,
                eventUuids: scheduleEvents.map((x) => x.uuid),
            })
        ).data as any
        return [parseSchedule(data.schedule), data.message]
    }

    updateScheduleWithEvents = async (schedule: ScheduleWithEvents): Promise<[Schedule, ResponseMessage?]> => {
        const data = (
            await this.axios.post('/Api/Schedules/UpdateAndReanalyzeSchedule', {
                schedule: convertScheduleForServer(schedule),
                hasNewDuty: false,
            })
        ).data as any
        return [parseSchedule(data.schedule), data.message]
    }

    getScheduleParameters = async (scheduleId: number): Promise<ScenarioParameters> => {
        const responseData = (await this.axios.get(`/Api/Schedules/${scheduleId}/Parameters`)).data
        return parseScenarioParameters(responseData)
    }

    updateScheduleParameters = async (
        scheduleId: number,
        parameters: ScenarioParameters,
    ): Promise<[Schedule, ResponseMessage?]> => {
        const responseData = (await this.axios.put(`/Api/Schedules/${scheduleId}/Parameters`, parameters)).data as any
        return [parseSchedule(responseData.schedule), responseData.message]
    }

    resetEditedSleep = async (scheduleId: number): Promise<[Schedule, ResponseMessage?]> => {
        const responseData = (await this.axios.post(`/Api/Schedules/${scheduleId}/ResetExplicitSleep`)).data as any
        return [parseSchedule(responseData.schedule), responseData.message]
    }

    addEvent = async (scheduleEvent: ScheduleEventRecord): Promise<[Schedule, ResponseMessage?]> => {
        // server doesn't accept "Duty" as an event type, so we need to switch it here before sending.
        const isDuty = scheduleEvent.type === 'Duty'
        if (isDuty) {
            scheduleEvent.type = 'Work'
        }

        const autoEventsState = isDuty ? 'NotCreated' : 'Deleted'
        const postData = {
            dutyItemState: [
                {
                    state: autoEventsState,
                    workType: 'Preparation',
                },
                {
                    state: autoEventsState,
                    workType: 'CommuteToWork',
                },
                {
                    state: autoEventsState,
                    workType: 'Brief',
                },
                {
                    state: autoEventsState,
                    workType: 'Debrief',
                },
                {
                    state: autoEventsState,
                    workType: 'CommuteFromWork',
                },
                {
                    state: autoEventsState,
                    workType: 'Unwind',
                },
            ],
            event: convertEventForServer(scheduleEvent),
        }
        const responseData = (await this.axios.post('/Api/Schedules/CreateEvent', postData)).data as any
        const parsedSchedule = parseSchedule(responseData.schedule)
        return [parsedSchedule, responseData.message]
    }

    editEvent = async (scheduleEvent: ScheduleEventRecord): Promise<[Schedule, ResponseMessage?]> => {
        // need to change enum values to strings
        const postData = convertEventForServer(scheduleEvent)
        const responseData = (await this.axios.post('/Api/Schedules/EditEvent/', postData)).data as any
        const parsedSchedule = parseSchedule(responseData.schedule)
        return [parsedSchedule, responseData.message]
    }

    editDuty = async (request: UpdateDutyRequest): Promise<[Schedule, ResponseMessage?]> => {
        const responseData = (await this.axios.post('/Api/Schedules/EditDuty', request)).data as any
        return [parseSchedule(responseData.schedule), responseData.message]
    }

    setDutyTime = async (request: UpdateDutyTimeRequest): Promise<[Schedule, ResponseMessage?]> => {
        const responseData = (await this.axios.post('/Api/Schedules/EditDutyDates', request)).data as any
        return [parseSchedule(responseData.schedule), responseData.message]
    }

    deleteSchedules = async (scenarioId: number, scheduleIds: number[]): Promise<void> => {
        await this.axios.delete('/Api/Scenarios/Schedules/', { scenarioId, scheduleIds })
    }

    duplicateSchedule = async (
        scheduleId: number,
        isSaveAs: boolean,
        name: string,
        newScenarioId: number,
        newScenarioName: string,
    ): Promise<number> => {
        const response = (
            await this.axios.post('/Api/Schedules/DuplicateSchedule/', {
                scheduleId,
                isSaveAs,
                name,
                newScenarioId,
                newScenarioName,
            })
        ).data
        return response.id as number
    }

    saveDraftSchedule = async (scheduleId: number, lastModified: Date): Promise<[Schedule, ResponseMessage?]> => {
        const data = (
            await this.axios.post('/Api/Schedules/SaveDraft/', {
                draftScheduleId: scheduleId,
                scheduleModified: lastModified,
            })
        ).data as any

        return [parseSchedule(data.schedule), data.message]
    }

    discardDraftSchedule = async (scheduleId: number, lastModified: Date): Promise<void> => {
        await this.axios.post('/Api/Schedules/DiscardDraft/', {
            draftScheduleId: scheduleId,
            scheduleModified: lastModified,
        })
    }

    getScheduleWithDetails = async (scheduleId: number): Promise<[Schedule, ResponseMessage?]> => {
        const data = (await this.axios.get(`/Api/Schedules/${scheduleId}`)).data as any
        return [parseSchedule(data.schedule), data.message]
    }

    saveScheduleSettingsForAnalysis = async (
        scheduleId: number,
        scheduleName: string,
        scheduleBase: string,
        scheduleTags: TagKeyValue[],
    ): Promise<[Schedule, ResponseMessage?]> => {
        const data = (
            await this.axios.put('/Api/Schedules/UpdateScheduleSettingsForAnalysis', {
                scheduleId,
                scheduleName,
                scheduleBase,
                scheduleTags,
            })
        ).data as any
        return [parseSchedule(data.schedule), data.message]
    }

    saveScheduleViewSettings = async (scheduleId: number, scheduleViewSettings: ViewSettings) => {
        const viewSettings = {
            ...scheduleViewSettings,
            timeMode: TimeModeEnum[scheduleViewSettings.timeMode].toString(),
            viewMode: ScheduleDetailsViewMode[scheduleViewSettings.viewMode].toString(),
        }
        await this.axios.put('/Api/Schedules/UpdateScheduleViewSettings', {
            scheduleId,
            scheduleViewSettings: viewSettings,
        })
    }

    getScenarioNamesList = async (): Promise<any[]> => {
        const scenarioNames = (await this.axios.get('/Api/Scenarios/GetListForSaveAs')).data as any[]
        return scenarioNames
    }

    getScenariosList = async (): Promise<Scenario[]> => {
        const data = (await this.axios.get('/Api/Scenarios')).data as any[]
        return data.map(parseScenario)
    }

    deleteScenarios = async (scenarioIds: number[]): Promise<void> => {
        await this.axios.delete('/Api/Scenarios', scenarioIds)
    }

    duplicateScenario = async (scenarioId: number, name: string): Promise<void> => {
        await this.axios.post('/Api/Scenarios/DuplicateScenario/', {
            scenarioId,
            name,
        })
    }

    renameScenario = async (scenarioId: number, scenarioName: string): Promise<void> => {
        await this.axios.put('/Api/Scenarios/', {
            id: scenarioId,
            name: scenarioName,
        })
    }

    updateLastViewed = async (id: number): Promise<void> => {
        await this.axios.put('/Api/Schedules/UpdateLastViewed/', {
            id,
        })
    }

    createNewEventsSchedule = async (createSchedule: CreateEventsSchedule): Promise<[Schedule, ResponseMessage?]> => {
        const data = (await this.axios.post('/Api/Schedules/CreateNewEventsSchedule', createSchedule)).data as any
        return [parseSchedule(data.schedule), data.message]
    }

    createNewShiftsSchedule = async (
        createSchedule: CreateUpdateShiftsSchedule,
    ): Promise<[Schedule, ResponseMessage?]> => {
        const data = (await this.axios.post('/Api/Shifts/CreateOrUpdateShiftsSchedule', createSchedule)).data as any
        return [parseSchedule(data.schedule), data.message]
    }

    updatePatternsShifts = async (patternsShifts: UpdatePatternsShifts): Promise<PatternsShifts> => {
        await this.axios.post('/Api/Shifts/UpdatePatternsShifts', patternsShifts)
        // api should be fixed to actually return the updated object with proper ids, but since it doesn't, just call again.
        return this.getPatternsShifts()
    }

    getNewScheduleDefaultsForScenario = async (scenarioId: number): Promise<NewScheduleDefaults> => {
        const data = (await this.axios.get(`/Api/Schedules/GetNewScheduleDefaultsForScenario/${scenarioId}`))
            .data as Array<any>
        return parseNewScheduleDefaults(data)
    }

    getScenariosWithMetrics = async (): Promise<Array<Scenario>> => {
        const data = (await this.axios.get('/Api/Scenarios/ScenariosWithMetrics')).data as Array<any>
        return data.map<Scenario>(parseScenario)
    }

    getScenarioById = async (scenarioId: number): Promise<Scenario> => {
        const data = (await this.axios.get(`/Api/Scenarios/${scenarioId}`)).data as any
        return parseScenario(data)
    }

    getScenarioSchedules = async (scenarioId: number): Promise<Array<Schedule>> => {
        const data = (await this.axios.get(`/Api/Scenarios/${scenarioId}/Schedules`)).data as Array<any>
        return data.map<Schedule>(parseSchedule)
    }

    getUsers = async (): Promise<Array<User>> => {
        const data = (await this.axios.get('/Api/Users')).data as Array<any>
        return data.map<User>(parseUser)
    }

    getUserSharing = async (itemIds: number[], itemType: ShareType): Promise<ShareUsersConfiguration> => {
        const data = (
            await this.axios.get(`/Api/Sharing/ItemShareUsers?itemIds=${itemIds.join(',')}&itemType=${itemType}`)
        ).data
        return data
    }

    updateUserSharing = async (request: ShareUsersConfiguration): Promise<void> => {
        await this.axios.post('/Api/Sharing/UpdateUserSharing/', request)
    }

    getFileProcessingRecords = async (scenarioId?: number): Promise<Array<FileProcessing>> => {
        let url = '/Api/FileProcessing/'
        if (scenarioId) {
            url += scenarioId.toString()
        }
        const data = (await this.axios.get(url)).data as Array<any>
        return data.map<FileProcessing>(parseFileProcessing)
    }

    getMetadata = async (): Promise<MetaData> => {
        const data = (await this.axios.get('/Api/Metadata')).data
        return parseMetadata(data)
    }

    getAppVersion = async (): Promise<string> => {
        return (await this.axios.get('/Api/Metadata/AppVersion')).data
    }

    getActiveUsersAndLicenseLimit = async (): Promise<AccountLicense> => {
        return (await this.axios.get('/Api/Users/ActiveUsersAndLicenseLimit')).data
    }

    signOut = async (): Promise<void> => {
        try {
            await this.axios.post('/Api/Users/LoggedOut')
            this.sessionControl.setIsLoggedIn(false)
        } finally {
            cookies.deleteSessionCookie()
        }
    }

    authenticateCredentials = async (
        emailAddress: string,
        password: string,
        keepMeLoggedIn: boolean,
    ): Promise<AuthToken> => {
        try {
            const formData = new FormData()
            formData.append('email', emailAddress)
            formData.append('pass', password)
            formData.append('keepMeLoggedIn', keepMeLoggedIn.toString())

            const response = await this.axios.post('/Api/Users/AuthenticateCredentials', formData)

            const authToken = response.data as AuthToken
            cookies.setSessionCookie(authToken.tokenValue, keepMeLoggedIn)
            this.sessionControl.setIsLoggedIn(true)
            return authToken
        } catch (err) {
            cookies.deleteSessionCookie()
            throw err
        }
    }

    authenticateToken = async (sessionToken: string): Promise<AuthToken | false> => {
        try {
            const tokenObject = await (await this.axios.get(`/Api/Users/AuthenticateToken?token=${sessionToken}`)).data
            this.sessionControl.setIsLoggedIn(true)
            return tokenObject
        } catch (error) {
            cookies.deleteSessionCookie()
            return false
        }
    }

    getEventsFilterConfig = async (): Promise<EventFilterConfig> => {
        const config = (await this.axios.get('/Api/Parameters/EventsFilterConfig')).data as EventFilterConfig
        config.configItems.forEach((x) => {
            x.type = x.type.toLowerCase() as ReportItemDefinitionDataType
        })
        return config
    }
}

export default SFApi
