import { DatePicker, DatePickerChangeEvent } from '@progress/kendo-react-dateinputs'
import { Grid } from '@progress/kendo-react-grid'
import { ChangeEvent, FormEvent, memo, useEffect, useMemo, useRef, useState } from 'react'
import { Col, Dropdown, Form, Modal } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { isDesktopMode } from 'services/axios/axios-sfc'
import globals from 'services/global/globals'
import { getLowestId } from 'services/utilities/arrayUtils'
import { scrollToRow } from 'services/utilities/kendoGridUtils'
import { handleApiError, showResponseMessage } from 'services/utilities/toastrUtils'
import { RootState } from 'store/store'
import MetaData from 'types/Metadata'
import Schedule, { CreateUpdateShiftsSchedule } from 'types/Schedule'
import {
    createShift,
    getShiftsHighestDayNumber,
    isShiftNameInvalid,
    isShiftTypeInvalid,
    Pattern,
    PatternsShifts,
    setScheduleShiftDate,
    Shift,
    ShiftSchedule,
    sortShifts,
    updateShiftToMatchSegment,
} from 'types/Shifts'
import ButtonCustom from 'views/Common/Buttons/ButtonCustom'
import IconButton from 'views/Common/Buttons/IconButton'
import SplitIconButton from 'views/Common/Buttons/SplitIconButton'
import ToolbarDropdownButton from 'views/Common/Buttons/ToolbarDropdownButton'
import ConfirmationDialog from 'views/Common/GenericDialogs/ConfirmationDialog'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'
import LoadingSpinner from 'views/Common/GenericDialogs/LoadingSpinner'
import ModalWrapper from 'views/Common/GenericDialogs/ModalWrapper'
import StationAutoSuggestInput from 'views/Common/Inputs/StationAutoSuggestInput'
import FormLabelCustom from 'views/Common/Widget/FormLabelCustom'
import InfoIcon from 'views/Common/Widget/InfoIcon'
import AddMultipleShiftsOptionsDialog, { AddMultipleShiftsValue } from './AddMultipleShiftsOptionsDialog'
import PatternsAndShiftsDialog from './PatternsAndShiftsDialog'
import ShiftsGrid from './ShiftsGrid'

export interface ShiftDialogProps {
    schedule?: Schedule
    scenarioName?: string
    scenarioId?: number
    shiftSchedule?: ShiftSchedule
    modeEdit?: boolean
    closeCallback: (dialogResult: DialogResultEnum, newSchedule?: Schedule) => void
}

const ToastOptions = {
    autoClose: 5000,
}

type DialogMode =
    | 'ShiftDialog'
    | 'PatternsAndShiftsDialog'
    | 'AddMultipleShiftsDialog'
    | 'WarningAboutResettingSleepAndMarkers'

const ShiftScheduleDialogContent = (props: ShiftDialogProps) => {
    const gridRef = useRef<Grid>(null)
    const [scenarioName, setScenarioName] = useState('')
    const formRef = useRef<HTMLFormElement>(null)
    const [patternsAndShifts, setPatternsAndShifts] = useState<PatternsShifts | null>(null)
    const [shiftSchedule, setShiftScheduleDefault] = useState(props.shiftSchedule)
    const patterns = [...(patternsAndShifts?.patterns.sort((a, b) => a.name.localeCompare(b.name)) || [])]
    const [duration, setDuration] = useState<number>(0)
    const customPattern = { id: 0, name: 'Custom', uuid: '', shifts: [], duration: 0 }
    patterns.unshift(customPattern)
    const [showLoadingSpinner, setShowLoadingSpinner] = useState(false)
    const [dialogMode, setDialogMode] = useState<DialogMode>('ShiftDialog')
    const [validatedForm, setValidatedForm] = useState(false)
    const [selectedPattern, setSelectedPattern] = useState(
        patterns.find((x) => x.id === props.shiftSchedule?.patternId) || customPattern,
    )
    const [selectedPatternShifts, setSelectedPatternShifts] = useState<Shift[]>(props.shiftSchedule?.shifts || [])
    const [gridCheckedShifts, setGridCheckedShifts] = useState<number[]>([])
    const [scheduleStart, setScheduleStart] = useState<Date | null>(null)
    const [scheduleStartCustomPattern, setScheduleStartCustomPattern] = useState<Date | null>(null)
    const [submitting, setSubmitting] = useState<boolean>(false)
    const [renamed, setRenamed] = useState<boolean>(props.modeEdit || false)
    const metadata = useSelector<RootState, MetaData>((x) => x.app.metadata!)
    const api = globals.getApi()

    useEffect(() => {
        const loadData = async () => {
            const loadedPatternsAndShifts = await api.getPatternsShifts()
            setPatternsAndShifts(loadedPatternsAndShifts)
            // Kendo cell editing requires a unique identifier to function properly
            // Transform shift segment to have an incrementing identifier
            // This can be removed when we get rid of the Desktop version
            if (isDesktopMode()) {
                let counterId = 1
                props.shiftSchedule?.shifts.forEach((shift) => {
                    shift.id = counterId
                    counterId++
                })
            }
            if (!shiftSchedule) {
                // is creating new shift schedule, so fetch Schedule defaults (Name, Base, etc)
                const newScheduleDefaults = await api.getNewScheduleDefaultsForScenario(props.scenarioId || 0)
                const defaultStartDate = new Date()
                setShiftScheduleDefault({
                    baseCode: newScheduleDefaults.baseCode,
                    scenarioId: props.scenarioId || 0,
                    scheduleName: newScheduleDefaults.scheduleName,
                    startDate: defaultStartDate,
                    lastModified: new Date(),
                    scheduleId: 0,
                    duration: 0,
                    patternId: 0,
                    shifts: [],
                })
                setScenarioName(props.scenarioName || newScheduleDefaults.scenarioName)
                setScheduleStart(defaultStartDate)
            } else {
                setScheduleStart(shiftSchedule.startDate)
                const loadedSelectedPattern = loadedPatternsAndShifts.patterns.find(
                    (x) => x.id === shiftSchedule.patternId,
                )
                if (loadedSelectedPattern) {
                    setSelectedPattern(loadedSelectedPattern)
                }
            }
        }
        loadData()
    }, [api, props, shiftSchedule])

    useEffect(() => {
        // If Custom Pattern then update the date for shifts
        if (selectedPattern.id === 0) {
            if (scheduleStartCustomPattern === scheduleStart) {
                // if custom pattern already at start
                return
            }
            const patternShifts = selectedPatternShifts.map((shift) => {
                if (scheduleStart) {
                    setScheduleShiftDate(shift, scheduleStart!)
                }
                return shift
            })
            sortShifts(patternShifts)
            setScheduleStartCustomPattern(scheduleStart)
            setDuration(getShiftsHighestDayNumber(selectedPatternShifts))
        }
    }, [scheduleStart, scheduleStartCustomPattern, selectedPattern, selectedPatternShifts])

    useEffect(() => {
        const scheduleStartDate = scheduleStart!
        if (selectedPattern.shifts.length === 0) {
            return
        }
        const patternDuration = selectedPattern.duration
        const scheduleShifts = [...selectedPattern.shifts]
        const shifts: Shift[] = []
        // v5 logic reuse for shift day number/dates
        // This is the duration they set in the UI
        const scheduleDurationExpected = duration
        const patternShifts = selectedPattern.shifts
        // Create rowsToBuild which will dictate row output
        // Shift rows to build
        let shiftRowsToBuild = 0
        let j = 0
        let repeats = 0
        let shiftDay = 0
        let scheduleDay = 0
        // eslint-disable-next-line
        while (true) {
            if (j >= selectedPattern.shifts.length) {
                j = 0
                repeats++
            }
            const shift = selectedPattern.shifts[j]
            shiftDay = shift.shiftDayNumber
            scheduleDay = shiftDay + repeats * patternDuration
            if (scheduleDay > scheduleDurationExpected) {
                break
            }
            j++
        }
        shiftRowsToBuild = j + repeats * selectedPattern.shifts.length
        let endOfGroup = false
        let doneWithOldRows = false
        let groupCountdown = patternDuration
        let shiftDayNumber = 0
        const oldRowsToBuild = 0
        for (let i = 0; i < shiftRowsToBuild; i++) {
            doneWithOldRows = i > oldRowsToBuild
            if (doneWithOldRows) {
                groupCountdown--
                endOfGroup = groupCountdown === 0
            }
            if (!doneWithOldRows) {
                shiftDayNumber++
            }
            if (doneWithOldRows && endOfGroup) {
                shiftDayNumber++
                groupCountdown = patternDuration
            }
            let shiftName = ''
            let shiftType = ''
            let shiftDuration = 0
            let shiftTimeStart = ''
            let shiftTimeEnd = ''
            let sourceShiftId = 0
            if (scheduleShifts) {
                let shift = null
                let currentTemplate = 'none'
                if (patternShifts && patternShifts.length > 0) {
                    shift = patternShifts[i % patternShifts.length]
                    currentTemplate = 'pattern'
                }
                if (shift) {
                    shiftName = shift.name
                    shiftTimeStart = shift.startTime
                    shiftTimeEnd = shift.calculatedEndTime
                    shiftDuration = shift.duration
                    shiftType = shift.shiftType
                    const existSourceShiftId = shift.sourceShiftId
                    if (
                        patternShifts.filter((f: Shift) => {
                            return f.sourceShiftId === existSourceShiftId
                        }).length > 0
                    ) {
                        sourceShiftId = shift.sourceShiftId
                    }
                    if (currentTemplate === 'pattern' && i >= patternShifts.length) {
                        // for repeating pattern greater than duration
                        shiftDayNumber =
                            shift.shiftDayNumber + selectedPattern.duration * Math.floor(i / patternShifts.length)
                    } else {
                        shiftDayNumber = shift.shiftDayNumber
                    }

                    // overwrite explicit values with shift values?
                    if (sourceShiftId > 0) {
                        shift = patternShifts.find((o) => o.sourceShiftId === sourceShiftId)
                    }

                    if (shift) {
                        shiftName = shift.name
                        shiftDuration = shift.duration
                        shiftTimeStart = shift.startTime
                        shiftTimeEnd = shift.calculatedEndTime
                        shiftType = shift.shiftType
                    }
                }
            }
            shifts[i] = {} as Shift
            shifts[i].calculatedEndTime = shiftTimeEnd
            shifts[i].startTime = shiftTimeStart
            shifts[i].name = shiftName
            shifts[i].shiftDayNumber = shiftDayNumber
            shifts[i].duration = shiftDuration
            shifts[i].shiftType = shiftType
            shifts[i].sourceShiftId = !sourceShiftId ? 0 : sourceShiftId
            shifts[i].id = i
            setScheduleShiftDate(shifts[i], scheduleStartDate)
        }
        const rebuiltShifts = [...shifts]
        setSelectedPatternShifts(rebuiltShifts)

        // When the duration and schedule start changes rebuild the schedule
    }, [duration, scheduleStart, selectedPattern])

    /**
     * Switch the pattern dropdown selection to Custom
     */
    const setPatternToCustom = () => {
        if (selectedPattern.id !== 0) {
            const pattern = patterns.find((x) => x.id === 0)!
            setSelectedPattern(pattern)
            toast.warning('This schedule pattern is now custom')
        }
    }

    /**
     * User adds a single shift
     */
    const addSingleShiftHandler = () => {
        setSelectedPatternShifts((previous) => {
            const highestDay = getShiftsHighestDayNumber(previous)
            const shiftId = getLowestId(previous) - 1
            const newShift = {
                id: shiftId,
                name: '',
                shiftType: '',
                startTime: '00:00',
                calculatedEndTime: '00:00',
                duration: 0,
                calculatedScheduleDateString: '',
                shiftDayNumber: highestDay + 1,
                sourceShiftId: 0,
            }
            setScheduleShiftDate(newShift, scheduleStart!)
            scrollToRow(gridRef, previous.length + 1)
            return [...previous, newShift]
        })
        setPatternToCustom()
    }

    /**
     * Handle when the Add Multiple Shifts dialog is OK'd
     * @param scheduleDate
     * @param shifts
     * @param multipleShifts
     */
    const addMultipleShiftsHandler = (multipleShifts: AddMultipleShiftsValue) => {
        setSelectedPatternShifts((previous) => {
            const shifts = [...previous]
            const highestDayNumber = getShiftsHighestDayNumber(shifts)
            let shiftId = getLowestId(shifts) - 1
            let dayNumber = highestDayNumber + 1
            for (let i = 0; i < multipleShifts.numberOfDays; i++) {
                for (let j = 0; j < multipleShifts.numberOfShiftsPerDay; j++) {
                    const newShift = createShift(shiftId--, dayNumber)
                    setScheduleShiftDate(newShift, scheduleStart!)
                    shifts.push(newShift)
                }
                dayNumber++
            }
            scrollToRow(gridRef, shifts.length)
            return shifts
        })
        setPatternToCustom()
    }

    const setSelectedPatternAndShifts = (patternsToUse: Pattern[], patternId: number) => {
        const pattern = patternsToUse.find((x) => x.id === patternId)!
        if (pattern.shifts.length > 0) {
            setDuration(pattern.duration)
        }
        setSelectedPattern(pattern)
        if (patternId > 0) {
            // custom pattern selected, so change to those shifts
            const patternShifts = pattern.shifts.map((shift) => {
                setScheduleShiftDate(shift, scheduleStart!)
                return shift
            })
            sortShifts(patternShifts)
            setSelectedPatternShifts(patternShifts)
        }

        // set schedule name based on pattern name
        if (!renamed) {
            shiftSchedule!.scheduleName = pattern.name
        }
    }

    /**
     * Handles changing the pattern selection.  Will update the shifts list as necessary.
     * @param e
     */
    const patternChangeHandler = (e: ChangeEvent<HTMLSelectElement>) => {
        const patternId = parseInt(e.target.value)
        setSelectedPatternAndShifts(patterns, patternId)
    }

    /**
     * Update all the shifts that are 'checked' in the grid to match the given one.  This is a UI bulk operation.
     * @param shiftSeg
     */
    const setSelectedGridShiftsToMatchShiftSegmentDefinition = (shiftSeg: Shift) => {
        setSelectedPatternShifts((previous) => {
            return [
                ...previous.map((x) => {
                    // update the selected shifts
                    if (gridCheckedShifts.includes(x.id)) {
                        const updated = { ...x }
                        updateShiftToMatchSegment(updated, shiftSeg)
                        return updated
                    }
                    return x
                }),
            ]
        })
    }

    /**
     * Remove shifts from the list.
     */
    const removeShiftsClickHandler = () => {
        if (selectedPattern.id !== 0) {
            // Set the pattern to custom
            setPatternToCustom()
        }
        // Delete shifts from pattern
        setSelectedPatternShifts((previous) => {
            return [...previous.filter((x) => !gridCheckedShifts.includes(x.id))]
        })
        setGridCheckedShifts([])
    }

    const getBaseFormField = (): HTMLInputElement =>
        Array.prototype.slice.call(formRef.current!.getElementsByTagName('input')).find((x) => x.name === 'baseStation')

    /**
     * Submit the schedule to the server.
     */
    const submitSchedule = async () => {
        const createOrUpdateRequest: CreateUpdateShiftsSchedule = {
            baseCode: shiftSchedule!.baseCode,
            scenarioName,
            scenarioId: props.scenarioId || 0,
            scheduleName: shiftSchedule!.scheduleName,
            patternId: selectedPattern.id,
            startDate: scheduleStart!,
            shifts: selectedPatternShifts.map((x) => ({ ...x, id: 0 })),
        }

        if (props.schedule) {
            createOrUpdateRequest.scheduleId = props.schedule.id
            createOrUpdateRequest.lastModified = props.schedule.modified
        }

        setSubmitting(true)

        try {
            setShowLoadingSpinner(true)
            const [newSchedule, message] = await api.createNewShiftsSchedule(createOrUpdateRequest)
            showResponseMessage(message)
            props.closeCallback(DialogResultEnum.Completed, newSchedule)
        } catch (err: any) {
            handleApiError(err)
            setSubmitting(false)
        } finally {
            setShowLoadingSpinner(false)
        }
    }

    const parseTime = (t: string) => {
        const d = new Date(new Date('2000-01-02T00:00:00Z').setHours(0, 0, 0, 0)) // initialize to 01/01/2000 @ midnight to avoid dst issues downstream
        const time = t.match(/(\d+)(?::(\d\d))?\s*(p?)/)
        d.setHours(parseInt(time![1]) + (time![3] ? 12 : 0))
        d.setMinutes(parseInt(time![2]) || 0)
        return d
    }

    const getOverlapErrors = (shifts: Shift[]): string[] => {
        // note: this code originated in v5
        let atLeastOneEvent = false
        let overlappingShifts = false
        let previousShiftEndDate: Date | null = null
        let previousShiftIsWork = false

        const errors: string[] = []

        shifts.forEach((shift) => {
            if (shift.duration > 0) {
                atLeastOneEvent = true

                const shiftStartTime = parseTime(shift.startTime)
                const shiftDuration = shift.duration
                const shiftIsWork = shift.shiftType === 'Crewing' || shift.shiftType === 'Non-Crewing'

                const shiftStartDate = new Date(shiftStartTime)
                shiftStartDate.setDate(shift.shiftDayNumber)
                shiftStartDate.setSeconds(0)
                shiftStartDate.setMilliseconds(0)

                const shiftEndDate = new Date(shiftStartDate)
                shiftEndDate.setMinutes(shiftEndDate.getMinutes() + shiftDuration)

                if (
                    previousShiftEndDate &&
                    shiftStartDate < previousShiftEndDate &&
                    shiftIsWork &&
                    previousShiftIsWork
                ) {
                    overlappingShifts = true
                    return
                }

                previousShiftEndDate = shiftEndDate
                previousShiftIsWork = shiftIsWork
            }
        })
        if (!atLeastOneEvent) {
            errors.push('All Shifts have zero duration and would not result in any events')
        }
        if (overlappingShifts) {
            errors.push('Shifts will result in overlapping events')
        }

        return errors
    }

    /**
     * User submits the form
     * @param event
     * @returns
     */
    const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        event.stopPropagation()

        const form = event.target as HTMLFormElement
        if (form.checkValidity() === false) {
            setValidatedForm(true)
            return
        }

        // ensure shifts are in chrono order before validating and submitting
        sortShifts(selectedPatternShifts)

        if (!selectedPatternShifts.length) {
            toast.error('Must add at least 1 shift', ToastOptions)
            return
        }

        if (selectedPatternShifts.find((x) => isShiftTypeInvalid(x) || isShiftNameInvalid(x))) {
            toast.error('Some shifts are not configured properly', ToastOptions)
            return
        }

        // check for overlaps
        const overlapErrors = getOverlapErrors(selectedPatternShifts)
        if (overlapErrors.length) {
            toast.error(overlapErrors.join(','), ToastOptions)
            return
        }

        // the server doesn't handle seconds and milliseconds properly.  Should fix that.
        scheduleStart!.setSeconds(0)
        scheduleStart!.setMilliseconds(0)

        // optional confirm dialog about wiping out sleep & markers
        if (props.schedule) {
            const events = props.schedule.events
            if (events.find((x) => x.isMarker() || x.isExplicitSleep())) {
                // these events will be wiped out, so warn the user
                setDialogMode('WarningAboutResettingSleepAndMarkers')
                return
            }
        }

        await submitSchedule()
    }

    const dialogTitle = useMemo(() => {
        if (props.schedule && props.schedule.id > 0) return 'Edit Shift Schedule'
        if (props.scenarioId || isDesktopMode()) return 'Add a new Shift Schedule'
        return 'Add a new Scenario and Shift Schedule'
    }, [props])

    return (
        <>
            {dialogMode === 'ShiftDialog' && patternsAndShifts && shiftSchedule && (
                <Form
                    id="scheduleSaveAsForm"
                    noValidate
                    validated={validatedForm}
                    onSubmit={submitHandler}
                    ref={formRef}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{dialogTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Row>
                            <Col sm="6">
                                <Form.Group>
                                    <FormLabelCustom htmlFor="txtScheduleName">Schedule Name</FormLabelCustom>
                                    <Form.Control
                                        id="txtScheduleName"
                                        autoComplete="off"
                                        type="text"
                                        placeholder="Enter a unique schedule name"
                                        value={shiftSchedule.scheduleName}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                            setShiftScheduleDefault((previous) => ({
                                                ...previous!,
                                                scheduleName: e.target.value,
                                            }))
                                            setRenamed(true)
                                        }}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter a unique schedule name
                                    </Form.Control.Feedback>
                                    <Form.Text className="text-muted">{metadata.scheduleNameCaption ?? ''}</Form.Text>
                                </Form.Group>
                            </Col>
                            <Col sm="1">
                                <Form.Group style={{ paddingTop: 37 }}>
                                    <span title="Rename Schedule on Pattern Selection">
                                        <Form.Check
                                            id="chkRename"
                                            title="Rename"
                                            checked={!renamed}
                                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                setRenamed(!e.target.checked)
                                            }}
                                            style={{ paddingLeft: 30, paddingBottom: 0 }}
                                        />
                                        <Form.Text
                                            className="text-muted"
                                            style={{ fontSize: '10px', padding: 0, margin: 0 }}
                                        >
                                            Rename
                                        </Form.Text>
                                    </span>
                                </Form.Group>
                            </Col>
                            <Col sm="5">
                                <Form.Group>
                                    {scenarioName && (isDesktopMode() || props.scenarioId != null) && (
                                        <>
                                            <FormLabelCustom>Scenario Name</FormLabelCustom>
                                            <p style={{ paddingLeft: '8px', paddingTop: '4px' }}>{scenarioName}</p>
                                        </>
                                    )}
                                    {!isDesktopMode() && !props.scenarioId && (
                                        <>
                                            <FormLabelCustom>Scenario Name</FormLabelCustom>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter a unique scenario name"
                                                value={props.scenarioName}
                                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                    setScenarioName(e.target.value)
                                                }
                                                required
                                                disabled={props.scenarioId != null}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please enter a unique scenario name
                                            </Form.Control.Feedback>
                                            <Form.Text className="text-muted">
                                                {metadata.scenarioNameCaption ?? ''}
                                            </Form.Text>
                                        </>
                                    )}
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col sm="4">
                                <Form.Group>
                                    <FormLabelCustom htmlFor="txtBaseStation">Base Station</FormLabelCustom>
                                    <StationAutoSuggestInput
                                        id="txtBaseStation"
                                        name="baseStation"
                                        placeholder="Start typing to search base stations"
                                        value={shiftSchedule?.baseCode}
                                        isInvalidMessage="Please provide a valid base station"
                                        onChange={(newValue: string) => {
                                            getBaseFormField().setCustomValidity('')
                                            setShiftScheduleDefault((previous) => ({
                                                ...previous!,
                                                baseCode: newValue,
                                            }))
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col sm="3">
                                <Form.Group>
                                    <FormLabelCustom>Start Date</FormLabelCustom>
                                    <DatePicker
                                        value={scheduleStart}
                                        format=""
                                        width="100%"
                                        onChange={(e: DatePickerChangeEvent) => {
                                            if (e.value) {
                                                setScheduleStart(e.value)
                                            }
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col sm="4">
                                <FormLabelCustom>Pattern</FormLabelCustom>
                                <Form.Control
                                    as="select"
                                    custom
                                    value={selectedPattern.id}
                                    onChange={patternChangeHandler}
                                >
                                    {patterns.map((pattern) => (
                                        <option key={pattern.id} value={pattern.id}>
                                            {pattern.name}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Col>
                            <Col sm="3">
                                <FormLabelCustom hidden={selectedPattern.id === 0}>Duration (days)</FormLabelCustom>
                                <Form.Control
                                    hidden={selectedPattern.id === 0}
                                    type="number"
                                    min={0}
                                    max={200}
                                    value={duration}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                        const parsed = parseInt(e.target.value)
                                        if (!Number.isNaN(parsed)) {
                                            setDuration(parsed)
                                        }
                                    }}
                                    required
                                />
                            </Col>
                        </Form.Row>

                        <Form.Row style={{ marginTop: '10px', marginBottom: '10px' }}>
                            <Col>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignContent: 'flex-end',
                                        alignItems: 'flex-end',
                                    }}
                                >
                                    <FormLabelCustom style={{ marginBottom: '0' }}>
                                        Shifts in pattern &quot;{selectedPattern.name}&quot;
                                    </FormLabelCustom>
                                    <div>
                                        <SplitIconButton
                                            tooltip="Add a Shift to the Pattern"
                                            tooltipSplit="Add Shift Options..."
                                            icon="bi-file-plus"
                                            onClick={() => {
                                                addSingleShiftHandler()
                                            }}
                                        >
                                            <Dropdown.Item onClick={() => addSingleShiftHandler()}>
                                                Add Single Shift
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={() => {
                                                    setDialogMode('AddMultipleShiftsDialog')
                                                }}
                                            >
                                                Add Multiple Shifts
                                            </Dropdown.Item>
                                        </SplitIconButton>

                                        <ToolbarDropdownButton
                                            title={
                                                <InfoIcon
                                                    icon="bi-pencil"
                                                    style={{
                                                        fontSize: '19px',
                                                        color: gridCheckedShifts.length === 0 ? '#bbb' : '#444',
                                                        width: '10px',
                                                    }}
                                                    tooltip="Edit Shift Name(s)"
                                                />
                                            }
                                            disabled={gridCheckedShifts.length === 0}
                                        >
                                            {patternsAndShifts.shiftSegments.map((shiftSeg) => (
                                                <Dropdown.Item
                                                    key={shiftSeg.name}
                                                    onClick={() =>
                                                        setSelectedGridShiftsToMatchShiftSegmentDefinition(shiftSeg)
                                                    }
                                                >
                                                    {shiftSeg.name}
                                                </Dropdown.Item>
                                            ))}
                                        </ToolbarDropdownButton>

                                        <IconButton
                                            tooltip="Delete Shift(s)"
                                            onClick={removeShiftsClickHandler}
                                            disabled={gridCheckedShifts.length === 0}
                                            toolbarLeftMargin
                                            icon="bi-trash"
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col>
                                <ShiftsGrid
                                    gridRef={gridRef}
                                    mode="Schedule"
                                    height={350}
                                    emptyGridText="Select a Pattern or click 'Add Shift' to build a schedule"
                                    shifts={selectedPatternShifts}
                                    shiftSegments={patternsAndShifts.shiftSegments}
                                    setShifts={setSelectedPatternShifts}
                                    setShiftsCustomizedFlag={setPatternToCustom}
                                    setSelectedShiftIds={setGridCheckedShifts}
                                />
                            </Col>
                        </Form.Row>
                    </Modal.Body>

                    <Modal.Footer>
                        <div className="mr-auto">
                            <ButtonCustom
                                isLarge
                                variant="white"
                                type="button"
                                onClick={() => {
                                    setDialogMode('PatternsAndShiftsDialog')
                                }}
                            >
                                Edit Patterns &amp; Shifts
                            </ButtonCustom>
                        </div>
                        <ButtonCustom isLarge variant="primary" type="submit" disabled={submitting}>
                            OK
                        </ButtonCustom>
                        <ButtonCustom
                            isLarge
                            variant="secondary"
                            onClick={() => {
                                props.closeCallback(DialogResultEnum.Cancelled)
                            }}
                        >
                            Cancel
                        </ButtonCustom>
                    </Modal.Footer>
                </Form>
            )}
            {dialogMode === 'AddMultipleShiftsDialog' && (
                <AddMultipleShiftsOptionsDialog
                    closeCallback={(result: DialogResultEnum, value?: AddMultipleShiftsValue) => {
                        if (result === DialogResultEnum.Completed && value) {
                            addMultipleShiftsHandler(value)
                        }
                        setDialogMode('ShiftDialog')
                    }}
                />
            )}
            {dialogMode === 'PatternsAndShiftsDialog' && patternsAndShifts && (
                <PatternsAndShiftsDialog
                    patternsShifts={patternsAndShifts}
                    closeCallback={(status, updatedPatternsShifts) => {
                        if (status === DialogResultEnum.Completed && updatedPatternsShifts) {
                            setPatternsAndShifts(updatedPatternsShifts)

                            if (selectedPattern.id > 0) {
                                const pattern = updatedPatternsShifts.patterns.find((x) => x.id === selectedPattern.id)
                                if (!pattern) {
                                    // selected pattern is not in patternsAndShifts, they must have deleted it so switch to custom
                                    setPatternToCustom()
                                } else {
                                    // update the pattern shifts to match the pattern definition
                                    setSelectedPatternAndShifts(updatedPatternsShifts.patterns, selectedPattern.id)
                                }
                            }
                        }
                        setDialogMode('ShiftDialog')
                    }}
                />
            )}

            {dialogMode === 'WarningAboutResettingSleepAndMarkers' && (
                <ConfirmationDialog
                    headerText="Confirm Edit Shift Schedule"
                    confirmedCallback={async () => {
                        await submitSchedule()
                    }}
                    closeCallback={() => {
                        setDialogMode('ShiftDialog')
                    }}
                >
                    <>
                        <p>
                            Editing this shift schedule will reset any Sleep or Marker events that may have been added
                            or modified.
                        </p>
                        <p>Are you sure you want to edit this shift schedule?</p>
                    </>
                </ConfirmationDialog>
            )}
            {showLoadingSpinner && <LoadingSpinner />}
        </>
    )
}

const ShiftScheduleDialog = (props: ShiftDialogProps) => {
    return (
        <ModalWrapper
            className="ShiftsDialog"
            closeCallback={() => {
                props.closeCallback(DialogResultEnum.Cancelled)
            }}
        >
            <ShiftScheduleDialogContent {...props} />
        </ModalWrapper>
    )
}

export default memo(ShiftScheduleDialog)
