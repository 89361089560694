import { D3Selection } from 'types/d3TypeHelpers'
import { ColorPalettes } from 'types/interfaces'
import { getColorFromPaletteForMetric } from 'views/Common/Palette/PalettedMetric'
import { Configs } from './ScheduleTimelineUtils'

interface LegendSection {
    sectionTitle: string
    legendItems: LegendItem[]
}

interface LegendItem {
    color?: string
    className?: string
    label: string
    desc?: string
}

const renderLegend = (parentGroup: D3Selection, colorPalettes: ColorPalettes) => {
    const legendGroup = parentGroup
        .append('g')
        .attr('class', 'legend')
        .attr('transform', 'translate(0,' + Configs.legendExtraYGap + ')')

    const renderLegendSections = (legendSections: LegendSection[]) => {
        legendSections.forEach((section, sectionIndex) => {
            const { sectionTitle, legendItems } = section
            const sectionYOffset = sectionIndex * Configs.legendSectionMarginTop

            // separate g group for each section to allow for pushing each one further down with additional y offset
            const legendSectionGroup = legendGroup
                .append('g')
                .attr('class', 'legend')
                .attr('transform', 'translate(0,' + sectionYOffset + ')')

            // section title
            legendSectionGroup
                .append('text')
                .attr('x', Configs.legendItemsIndent)
                .text(sectionTitle)
                .style('font-weight', Configs.boldFontWeight)

            // section items, pushed down below the title
            legendItems.forEach((item, index) => {
                const x =
                    Configs.legendItemsIndent +
                    Configs.legendItemsHorizontalSpaceBetween / 2 +
                    Configs.legendItemsHorizontalSpaceBetween * index

                // item colored block (note: it needs to be pushed up a big to be aligned with the label)
                const coloredBlockYOffset = -17
                legendSectionGroup
                    .append('rect')
                    .attr('x', x)
                    .attr('y', Configs.legendItemsMarginTop + coloredBlockYOffset)
                    .attr('width', Configs.legendRectangleWidth)
                    .attr('height', Configs.legendRectangleHeight)
                    .attr('class', item.className || '')
                    .attr('fill', item.color || 'transparent')
                    .attr('stroke', item.color === 'white' ? 'black' : '')

                // item label
                legendSectionGroup
                    .append('text')
                    .text(item.label)
                    .attr('x', x + Configs.legendRectangleWidth + 4)
                    .attr('y', Configs.legendItemsMarginTop)

                if (item.desc) {
                    legendSectionGroup
                        .append('text')
                        .text(item.desc)
                        .attr('x', x + Configs.legendRectangleWidth + 10)
                        .attr('y', Configs.legendItemsMarginTop + 15)
                }
            })
        })
    }

    const crewingEffectivenessLegendItems: LegendItem[] = colorPalettes.effectiveness.map((paletteItem) => {
        const item: LegendItem = {
            label: '',
            color: getColorFromPaletteForMetric('effectiveness', paletteItem.threshold + 1, colorPalettes),
        }
        return item
    })
    crewingEffectivenessLegendItems[0].label = 'Good'
    crewingEffectivenessLegendItems[1].label = 'Medium'
    crewingEffectivenessLegendItems[2].label = 'Fair'
    crewingEffectivenessLegendItems[3].label = 'Lowest'

    crewingEffectivenessLegendItems.push({
        label: 'Non-operating Work',
        desc: '(Preparation, commute, wait time, etc.)',
        color: Configs.nonCrewingColor,
    })

    const lightExposureLegendItems: LegendItem[] = [
        { label: 'Day', color: 'white' },
        { label: 'Twilight', className: 'twilightRectScheduleTimeline' },
        { label: 'Night', className: 'darkRectScheduleTimeline' },
    ]

    const sleepLegendItems: LegendItem[] = [
        { label: 'Predicted', className: 'sleepRect' },
        { label: 'Actual or Modified', className: 'explicitSleepRect' },
    ]

    const legendSections: LegendSection[] = [
        { sectionTitle: 'Operating Effectiveness (Alertness)', legendItems: crewingEffectivenessLegendItems },
        { sectionTitle: 'Light Exposure', legendItems: lightExposureLegendItems },
        { sectionTitle: 'Sleep', legendItems: sleepLegendItems },
    ]

    renderLegendSections(legendSections)
}

export default renderLegend
