import * as d3 from 'd3'
import dateTimeFormatting from 'services/formatting/dateTimeFormatting'
import { D3Selection } from 'types/d3TypeHelpers'
import { DetailData } from 'types/DetailItem'
import GraphSchedule from 'types/GraphSchedule'
import { TimeModeEnum } from 'types/interfaces'
import ScheduleEvent from 'types/ScheduleEvent'
import { getColorFromPaletteForMetric } from 'views/Common/Palette/PalettedMetric'
import {
    formatDateWithTZOffset,
    getGraphMidnightDates,
} from '../ScheduleDetails/EffectivenessGraph/EffectivenessGraphTimeUtils'
import { XScale } from '../ScheduleDetails/EffectivenessGraph/EffectivenessGraphTypes'
import renderLegend from './ScheduleTimelineLegend'
import { calculateExtraSleepLabelYOffset, Configs, LabelsShiftedDown, SleepDuration } from './ScheduleTimelineUtils'

interface CrewingEffectivenessSection {
    start: number
    end: number
    effectivenessColor: string
}

interface DutyLocation {
    location: string
    time: Date
    type: 'start' | 'end'
}

interface XScaleOptions {
    earlyStart?: Date
    lateEnd?: Date
}

class MultiScheduleTimelineRenderer {
    private dutyLocations: DutyLocation[] = []
    private sleepDurations: SleepDuration[] = []

    constructor(
        private graphContainer: HTMLDivElement,
        private schedule: GraphSchedule,
        private weekStartDay: 0 | 1 | 2 | 3 | 4 | 5 | 6,
    ) {
        this.dutyLocations = this.getDutyLocationsFromScheduleEvents(schedule.events)
        this.sleepDurations = this.getSleepDurationsFromScheduleEvents(schedule.events)
    }

    /**
     * Create a function that scales from date objects to x-axis pixels on the graph
     */
    private setXScale = (detailedData: DetailData[], options?: XScaleOptions): XScale => {
        const xScale: XScale = d3.scaleUtc().range([0, Configs.svgWidth])
        let min = 0
        if (options?.earlyStart) {
            min = options.earlyStart.getTime()
        } else {
            min = d3.min(detailedData.map((d) => d.utcTime.getTime())) || new Date(0).getTime()
        }

        let max = new Date().getTime()
        if (options?.lateEnd) {
            max = options.lateEnd.getTime()
        } else {
            max = d3.max(detailedData.map((d) => d.utcTime.getTime())) || new Date().getTime()
        }
        xScale.domain([min, max])

        return xScale
    }

    private getEffectivenessColor = (effectiveness: number) => {
        return getColorFromPaletteForMetric('effectiveness', effectiveness, this.schedule.colorPalettes)
    }

    private generateCrewingEffectivenessRectangles = (detailedData: DetailData[]) => {
        // generate array of objects with start and end times and effectiveness
        // array of objects with some properties of DetailData
        // const d = this.schedule.detailDataWithTicks.detailData

        const data: CrewingEffectivenessSection[] = []
        const closeOutRectangle = (effectivenessColor: string, start: number, end: number) => {
            data.push({ effectivenessColor, start, end })
        }

        let previousColor = ''
        let startTime = 0
        detailedData.forEach((d) => {
            if (d.crewing) {
                const color = this.getEffectivenessColor(d.effectiveness)

                if (startTime === 0) {
                    // initial iteration (in crewing anyway
                    startTime = d.utcTime.getTime()
                    previousColor = color
                }

                // if not the same as previous (or is last minute), add a new object to the array
                if (color !== previousColor) {
                    closeOutRectangle(previousColor, startTime, d.utcTime.getTime())
                    startTime = d.utcTime.getTime()
                    previousColor = color
                }
            } else if (startTime !== 0) {
                // we already detected crewing data, but we've moved on to a non-crewing section
                // so close out the rectangle; and reset startTime for the next crewing section if exists
                closeOutRectangle(previousColor, startTime, d.utcTime.getTime())
                startTime = 0
            }
        })

        // add the last rectangle if it's still open
        if (startTime !== 0) {
            closeOutRectangle(previousColor, startTime, detailedData[detailedData.length - 1].utcTime.getTime())
        }
        return data
    }

    private getKeyRectangleHeight = (scheduleEvent: ScheduleEvent): number => {
        const quality = scheduleEvent.quality
        const defaultHeight = Configs.rowHeight
        return quality ? defaultHeight * (quality as number) : defaultHeight
    }

    private renderDaylightCycle = (parentGroup: D3Selection, xScale: XScale, yOffset: number) => {
        const group = parentGroup.append('g')
        group
            .selectAll('underlay-rectangles')
            .data(this.schedule.lightIntervals)
            .enter()
            .append('rect')
            .attr('class', (d) => {
                const cssClassSuffix = d.getDataType() === 'daylight' ? '' : 'ScheduleTimeline'
                return d.getDataType() + 'Rect' + cssClassSuffix
            })
            .attr('x', (d) => xScale(d.getStartMs()))
            .attr('y', yOffset)
            .attr('width', (d) => xScale(d.getEndMs()) - xScale(d.getStartMs()))
            .attr('height', () => Configs.rowHeight * 2)
    }

    private renderRectanglesAndLabels = (parentGroup: D3Selection, xScale: XScale, yOffset: number) => {
        const group = parentGroup.append('g')
        const keyRects = group.selectAll('rect')

        const dutyDurations = []
        for (let i = 0; i < this.dutyLocations.length; i += 2) {
            const startTime = this.dutyLocations[i].time
            const endTime = this.dutyLocations[i + 1].time
            dutyDurations.push({ startTime, endTime })
        }

        const yInsetAmount = 1.0

        // draw rectangles
        keyRects
            .data(dutyDurations)
            .enter()
            .append('rect')
            .attr('x', (d) => xScale(d.startTime.getTime()))
            .attr('y', yOffset + yInsetAmount / 2)
            .attr('width', (d) => xScale(d.endTime.getTime()) - xScale(d.startTime.getTime()))
            .attr('height', Configs.rowHeight - yInsetAmount)
            .attr('stroke', 'black')
            .attr('stroke-width', '1.0px')
            .attr('fill', 'none')

        keyRects
            .data(this.dutyLocations)
            .enter()
            .append('text')
            .text((d, i) => {
                if (i > 0) {
                    const previousLocation = this.dutyLocations[i - 1].location
                    if (previousLocation === d.location) {
                        return ''
                    }
                }

                return d.location
            })
            .attr('x', (d) => {
                const positionAdjustment =
                    d.type === 'start' ? Configs.dutyLabelXOffsetStartLocation : Configs.dutyLabelXOffsetEndLocation
                return xScale(d.time.getTime()) + positionAdjustment
            })
            .attr('y', (d) => {
                const distanceFromTop =
                    d.type === 'start' ? Configs.dutyLabelYOffsetStartLocation : Configs.dutyLabelYOffsetEndLocation
                return yOffset + distanceFromTop
            })
            .attr('fill', 'black')
    }

    private renderSleepDurationLabels = (parentGroup: D3Selection, xScale: XScale, yOffset: number) => {
        const group = parentGroup.append('g')
        const keyRects = group.selectAll('rect')
        const labelLeftMargin = 5

        const sleepEndIsWithinScale = (time: Date, duration: number) => {
            const minimumScaleTime = xScale.domain()[0].getTime()
            const maxiumScaleTime = xScale.domain()[1].getTime()
            const sleepEnd = new Date(time.getTime() + duration * 60 * 1000)
            const endTime = sleepEnd.getTime()
            return endTime >= minimumScaleTime && endTime <= maxiumScaleTime
        }

        const filteredData = this.sleepDurations.filter((d) => sleepEndIsWithinScale(d.time, d.duration))
        const labelShiftedDown: LabelsShiftedDown = {}
        keyRects
            .data(filteredData)
            .enter()
            .append('text')
            .text((d) => {
                return dateTimeFormatting.formatDurationHoursAndMinutes(d.duration, {
                    hideZeroMinutes: true,
                    noSpace: true,
                })
            })
            .attr('x', (d) => {
                const minimumScaleTime = xScale.domain()[0].getTime()
                const time = d.time.getTime() < minimumScaleTime ? minimumScaleTime : d.time.getTime()
                return xScale(time) + labelLeftMargin
            })
            .attr('y', (_, i) => {
                return yOffset + 15 + calculateExtraSleepLabelYOffset(i, filteredData, labelShiftedDown)
            })
            .attr('fill', 'black')
    }

    /**
     * Draw in the shaded rectangles that represent events.
     */
    private drawAllEventRectangles = (
        parentGroup: D3Selection,
        xScale: XScale,
        yOffset: number,
        rowType: 'work' | 'sleep',
    ): void => {
        const filterInTypes = rowType === 'work' ? ['notCrewing', 'crewing'] : ['sleep', 'explicitSleep']
        const group = parentGroup.append('g')
        const keyRects = group.selectAll('rect')

        const eventIsWithinScale = (time: number, duration: number) => {
            const minimumScaleTime = xScale.domain()[0].getTime()
            const maxiumScaleTime = xScale.domain()[1].getTime()
            const sleepEnd = new Date(time + duration * 60 * 1000)
            const endTime = sleepEnd.getTime()
            const startTimeWithinScale = time >= minimumScaleTime && time <= maxiumScaleTime
            const endTimeWithinScale = endTime >= minimumScaleTime && endTime <= maxiumScaleTime
            return startTimeWithinScale || endTimeWithinScale
        }

        keyRects
            .data(
                this.schedule.events.filter(
                    (d) => filterInTypes.includes(d.getDataType()) && eventIsWithinScale(d.getStartMs(), d.duration),
                ),
            )
            .enter()
            .append('rect')
            .attr('class', (d) => {
                if (['sleep', 'explicitSleep'].includes(d.getDataType())) {
                    const editedSleepRect = d.isEditableSleep() ? ' editableSleepRect' : ''
                    return d.getDataType() + 'Rect keyRect' + editedSleepRect
                }
                return ''
            })
            .attr('x', (d) => xScale(d.getStartMs()))
            .attr('y', (d) => yOffset + Configs.rowHeight - this.getKeyRectangleHeight(d))
            .attr('fill', (d) => {
                if (d.getDataType() === 'notCrewing') {
                    return Configs.nonCrewingColor
                }
                return 'none'
            })
            .attr('stroke', (d) => {
                if (d.getDataType() === 'crewing') {
                    return 'grey'
                }
                return ''
            })
            .attr('stroke-width', '0.5px')
            .attr('width', (d) => xScale(d.getEndMs()) - xScale(d.getStartMs()))
            .attr('height', (d) => this.getKeyRectangleHeight(d))
    }

    private drawCrewingEffectivenessRectangles = (
        parentGroup: D3Selection,
        effectivenessRectangles: CrewingEffectivenessSection[],
        xScale: XScale,
        yOffset: number,
    ) => {
        // using crewingEffectivenessSections, draw the rectangles
        const group = parentGroup.append('g')
        group
            .selectAll('crewing-rectangles')
            .data(effectivenessRectangles)
            .enter()
            .append('rect')
            .attr('x', (d) => xScale(d.start))
            .attr('y', yOffset)
            .attr('width', (d) => xScale(d.end) - xScale(d.start))
            .attr('height', Configs.rowHeight)
            .attr('fill', (d) => d.effectivenessColor)
    }

    private drawOverallBorderRectangle = (
        parentGroup: D3Selection,
        yPosition: number,
        startTime: number,
        endTime: number,
        xScale: XScale,
    ) => {
        const startX = xScale(startTime)
        const width = xScale(endTime) - startX
        parentGroup
            .append('rect')
            .attr('y', yPosition)
            .attr('x', startX)
            .attr('width', width)
            .attr('height', Configs.rowHeight)
            .attr('class', 'graphBorder')
    }

    private renderStartOfWeekStations = (group: D3Selection, startTime: number, rowYTop: number) => {
        const dutyLocationsCopy = [...this.dutyLocations]
        const lastDutyLocation = dutyLocationsCopy.filter((d) => d.time.getTime() < startTime).pop()
        if (lastDutyLocation) {
            group
                .append('text')
                .text(lastDutyLocation.location)
                .attr('x', 5)
                .attr('y', rowYTop + Configs.dutyLabelYOffsetStartLocation)
        }
    }

    renderTimelineRowForWork = (
        group: D3Selection,
        effectivenessRectangles: CrewingEffectivenessSection[],
        xScale: XScale,
        startTime: number,
        endTime: number,
        rowYTop: number,
        rowIndex: number,
    ) => {
        const timelineContainer = group.append('g').attr('class', 'timelineContainer')
        this.drawCrewingEffectivenessRectangles(timelineContainer, effectivenessRectangles, xScale, rowYTop)
        this.drawAllEventRectangles(timelineContainer, xScale, rowYTop, 'work')
        this.renderRectanglesAndLabels(timelineContainer, xScale, rowYTop)
        this.drawOverallBorderRectangle(timelineContainer, rowYTop, startTime, endTime, xScale)
        if (rowIndex !== 0) {
            this.renderStartOfWeekStations(group, startTime, rowYTop)
        }
    }

    renderTimelineRowForSleep = (
        group: D3Selection,
        xScale: XScale,
        startTime: number,
        endTime: number,
        rowYTop: number,
    ) => {
        const timelineContainer = group.append('g').attr('class', 'timelineContainer')
        this.drawAllEventRectangles(timelineContainer, xScale, rowYTop, 'sleep')
        this.renderSleepDurationLabels(timelineContainer, xScale, rowYTop)
        this.drawOverallBorderRectangle(timelineContainer, rowYTop, startTime, endTime, xScale)
    }

    renderScheduleName = (group: D3Selection) => {
        group
            .append('text')
            .text(this.schedule.name)
            .attr('x', 0)
            .attr('y', Configs.topMargin)
            .style('font-weight', Configs.boldFontWeight)
            .style('font-size', Configs.scheduleNameFontSize)
            .attr('text-decoration', 'underline')
    }

    getFirstWeekStart = (): Date => {
        const data = this.schedule.detailDataWithTicks.detailData
        const firstMinuteTime = data[0].utcTime
        // set the start of the report to the start of the week
        const startOfReport = new Date(
            firstMinuteTime.getTime() - (firstMinuteTime.getDay() - this.weekStartDay) * 24 * 60 * 60 * 1000,
        )

        // if startOf report is later than firstMinute, then we need to go back a week
        if (startOfReport > firstMinuteTime) {
            startOfReport.setDate(startOfReport.getDate() - Configs.daysPerRow)
        }
        return startOfReport
    }

    getDetailedDataWeeks = (startOfReport: Date): DetailData[][] => {
        const firstWeekEnd = new Date(startOfReport.getTime() + Configs.daysPerRow * 24 * 60 * 60 * 1000)
        const data = this.schedule.detailDataWithTicks.detailData

        // put data into separate arrays, 1 week at a time, starting at startOfReport
        const weeks: DetailData[][] = []
        let week: DetailData[] = []
        const weekEnd = firstWeekEnd
        for (let i = 0; i < data.length; i++) {
            const d = data[i]
            if (d.utcTime < weekEnd) {
                week.push(d)
            } else {
                weeks.push(week)
                week = []
                weekEnd.setDate(weekEnd.getDate() + Configs.daysPerRow)
            }
        }

        // add the last week
        weeks.push(week)

        return weeks
    }

    getDutyLocationsFromScheduleEvents = (events: ScheduleEvent[]): DutyLocation[] => {
        const locations: DutyLocation[] = []
        let previousEvent: ScheduleEvent | null = null
        // eslint-disable-next-line no-restricted-syntax
        for (const evt of events.filter((e) => e.getDataType() === 'crewing' || e.getDataType() === 'notCrewing')) {
            if (!previousEvent) {
                // very first event
                locations.push({ location: evt.from, time: new Date(evt.getStartMs()), type: 'start' })
                previousEvent = evt
                // eslint-disable-next-line no-continue
                continue
            }

            // if there's a gap, then we need to add a boundary
            if (evt.getStartMs() - previousEvent.getEndMs() > 0) {
                locations.push({
                    location: previousEvent.to,
                    time: new Date(previousEvent.getEndMs()),
                    type: 'end',
                })
                locations.push({ location: evt.from, time: new Date(evt.getStartMs()), type: 'start' })
            }
            previousEvent = evt
        }

        // add the last boundary
        if (previousEvent) {
            locations.push({ location: previousEvent.to, time: new Date(previousEvent.getEndMs()), type: 'end' })
        }

        return locations
    }

    getSleepDurationsFromScheduleEvents = (events: ScheduleEvent[]): SleepDuration[] => {
        const sleepDurations: SleepDuration[] = []
        let previousEvent: ScheduleEvent | null = null
        // eslint-disable-next-line no-restricted-syntax
        for (const evt of events.filter((e) => e.getDataType() === 'sleep' || e.getDataType() === 'explicitSleep')) {
            if (!previousEvent) {
                // very first event
                sleepDurations.push({ duration: evt.duration, time: new Date(evt.getStartMs()) })
                previousEvent = evt
                // eslint-disable-next-line no-continue
                continue
            }

            if (evt.getStartMs() - previousEvent.getEndMs() === 0) {
                // no gap, so just add the duration to the previous duration
                sleepDurations[sleepDurations.length - 1].duration += evt.duration
            } else {
                // add a new sleep duration
                sleepDurations.push({ duration: evt.duration, time: new Date(evt.getStartMs()) })
            }
            previousEvent = evt
        }

        return sleepDurations
    }

    renderDates = (parentGroup: D3Selection, xScale: XScale, yPosition: number) => {
        const startDate = xScale.domain()[0]
        startDate.setHours(0, 0, 0, 0)
        const endDate = xScale.domain()[1]
        const midnightDatesWithOffsets = getGraphMidnightDates(
            this.schedule.detailDataWithTicks.detailData,
            startDate,
            endDate,
            TimeModeEnum.Base,
        )

        const dateFormat = dateTimeFormatting.getAppDateFormatNoYear()

        const midnightLineExtraHeight = 12
        const tickmarkHeight = midnightLineExtraHeight + Configs.rowHeight * 2
        const dayAxis = d3
            .axisTop<Date>(xScale)
            .tickFormat(d3.timeFormat(''))
            .tickValues(midnightDatesWithOffsets.map((x) => x.time))
            .tickSize(tickmarkHeight)

        parentGroup
            .append('g')
            .attr('class', 'xAxisDate axisHidden js-redraw')
            .attr('transform', 'translate(0,' + (yPosition + tickmarkHeight - midnightLineExtraHeight) + ')')
            .call(dayAxis)
            .selectAll('text')
            .style('text-anchor', 'start')
            // shift the date label to the right a little, and adjust for the extra height of the midnight line
            .attr('transform', 'translate(2,' + midnightLineExtraHeight + ')')
            .selectAll('tspan')
            .data((d) => {
                // not sure what is missing such that I have to cast here.
                const date = d as Date
                // NB: I'm using tz offset 0 here, implying UTC time even though the report
                // is in base time; this is because times aren't shown anyway, so no need to
                // convert to base time.
                const tzOffset = 0
                const formattedDateString = formatDateWithTZOffset(date, dateFormat, tzOffset)
                return [formattedDateString]
            })
            .enter()
            .append('tspan')
            .text(String)
            .attr('fill', Configs.dateFontColor)
            .style('font-size', Configs.dateFontColor)
    }

    renderWeekDayLabels = (group: D3Selection) => {
        // render the day labels in order based on weekStartDay which is a number from 1 to 7
        const dayLabels = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        const rotatedDayLabels = dayLabels
            .slice(this.weekStartDay - 1)
            .concat(dayLabels.slice(0, this.weekStartDay - 1))

        const dayLabelGroup = group.append('g').attr('class', 'dayLabelGroup')
        rotatedDayLabels.forEach((label, i) => {
            dayLabelGroup
                .append('text')
                .text(label)
                .attr('x', i * (Configs.svgWidth / 7))
                .attr('y', Configs.topMargin + Configs.scheduleNameMarginBottom)
                .attr('fill', Configs.dateFontColor)
                .style('font-size', Configs.dateFontColor)
        })

        // render a horizontal line to separate the day labels from the rest of the graph
        const horizontalLineYOffset = 20
        group
            .append('line')
            .attr('x1', 0)
            .attr('x2', Configs.svgWidth)
            .attr('y1', Configs.scheduleNameMarginBottom + horizontalLineYOffset)
            .attr('y2', Configs.scheduleNameMarginBottom + horizontalLineYOffset)
            .attr('stroke', Configs.dateFontColor)
            .attr('stroke-width', 1)
    }

    appendSvgToGraphContainer = (height: number) => {
        return d3
            .select(this.graphContainer)
            .append('svg')
            .attr('height', height)
            .attr('width', Configs.svgWidth)
            .append('g')
    }

    /**
     * Render the timeline
     */
    render = () => {
        const group = this.appendSvgToGraphContainer(Configs.headerSvgHeight)
        this.renderScheduleName(group)

        this.renderWeekDayLabels(group)

        const startOfReport = this.getFirstWeekStart()
        const weeksData = this.getDetailedDataWeeks(startOfReport)
        const gapBetweenWorkSleepRow = 0
        weeksData.forEach((weekData, i) => {
            const weekSvgGroup = this.appendSvgToGraphContainer(
                Configs.rowHeight * 2 + Configs.dateHeight + Configs.weekRowsGapBetween,
            )

            const xScaleOptions: XScaleOptions = {}
            let endOfReport = new Date()
            // scaling options account for first row whose data might not start at the beginning of the week
            // and the last row whose data might not end at the end of the week
            if (i === 0) {
                xScaleOptions.earlyStart = startOfReport
            }
            if (i === weeksData.length - 1) {
                const weekCount = weeksData.length
                endOfReport = new Date(startOfReport.getTime() + Configs.daysPerRow * 24 * 60 * 60 * 1000 * weekCount)
                xScaleOptions.lateEnd = endOfReport
            }

            const xScale = this.setXScale(weekData, xScaleOptions)
            const weekdataStart = weekData[0].utcTime.getTime()
            const weekdataEnd = weekData[weekData.length - 1].utcTime.getTime()

            const row2YPos = Configs.dateHeight + Configs.rowHeight + gapBetweenWorkSleepRow

            const effectivenessRectangles = this.generateCrewingEffectivenessRectangles(weekData)
            this.renderDaylightCycle(weekSvgGroup, xScale, Configs.dateHeight)
            this.renderDates(weekSvgGroup, xScale, Configs.dateHeight)
            this.renderTimelineRowForWork(
                weekSvgGroup,
                effectivenessRectangles,
                xScale,
                weekdataStart,
                weekdataEnd,
                Configs.dateHeight,
                i,
            )
            this.renderTimelineRowForSleep(weekSvgGroup, xScale, weekdataStart, weekdataEnd, row2YPos)
        })

        const legendGroup = this.appendSvgToGraphContainer(Configs.legendHeight)
        renderLegend(legendGroup, this.schedule.colorPalettes)
    }
}

export default MultiScheduleTimelineRenderer
