import React, { ChangeEvent, FormEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { Alert, Col, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import Select, { OnChangeValue } from 'react-select'
import { toast } from 'react-toastify'
import checkExistingRecalculationRequests from 'services/api/checkExistingRecalculationRequests'
import { isDesktopMode } from 'services/axios/axios-sfc'
import globals from 'services/global/globals'
import { convertQueryBuilderToSql } from 'services/queryBuilder/queryBuilderService'
import { handleApiError } from 'services/utilities/toastrUtils'
import { globalActions } from 'store/globalStore'
import { reportsActions } from 'store/reportingStore'
import { RootState } from 'store/store'
import ReportingMetadata, { IdLabelSet, ReportItemDefinition } from 'types/ReportingMetadata'
import {
    ConfigOption,
    getNewReport,
    getNewReportSeries,
    getScenarioSelection,
    QueryBuilder,
    Report,
    ReportDialogConfig,
    Series,
} from 'types/Reports'
import { ScenarioSelection } from 'types/ScenarioSelection'
import BigButton from 'views/Common/Buttons/BigButton'
import ButtonCustom from 'views/Common/Buttons/ButtonCustom'
import IconButton from 'views/Common/Buttons/IconButton'
import IconButtonAdd from 'views/Common/Buttons/IconButtonAdd'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'
import PageLayout from 'views/Common/Layout/PageLayout'
import FormLabelCustom from 'views/Common/Widget/FormLabelCustom'
import { Option, SelectConfig } from 'views/Common/Widget/ReactSelectTypes'
import ScenarioSelectorButton, { ScenarioSelectionOptions } from 'views/Common/Widget/ScenarioSelectorButton'
import ScenarioSelectorDesktop from 'views/Common/Widget/ScenarioSelectorDesktop'
import {
    ConfigPageFormBody,
    ConfigurationRow,
    IndentedBlock,
    PaddedCenteredContent,
} from 'views/Insights/Page/HazardClassConfiguration'
import DataGroupItem from 'views/Reports/Page/ReportConfigurationTypes'
import ScenarioSelectorDialog from 'views/Scenarios/Dialogs/ScenarioSelectionDialog'
import { getDataItemDefinitionByDataItem } from '../Components/reportConversion'
import ReportConfigFilterDialog from '../Dialogs/ReportConfigFilterDialog'
import ReportConfigDialogSeriesSelector from './ReportConfigSeriesSelector'

type DialogMode = 'None' | 'Filters' | 'ScenarioSelector'

const OrderDirectionAsc = 'Asc'
const OrderDirectionDesc = 'Desc'

const sortNumericValueOptions = (config: SelectConfig) => {
    config.options.sort((option1, option2) => {
        const option1Val = parseInt(option1.value)
        const option2Val = parseInt(option2.value)
        if (option1Val > option2Val) return 1
        if (option1Val < option2Val) return -1
        return 0
    })
}

const getDefaultNumericGroupMethods = (): Option[] => [
    {
        label: 'Histogram',
        value: 'HistogramBins',
    },
    {
        label: 'Effectiveness Palette',
        value: 'EffectivenessThresholds',
    },
    {
        label: 'Reservoir Palette',
        value: 'ReservoirThresholds',
    },
    {
        label: 'Percent Palette',
        value: 'PercentEffThresholds',
    },
]

const handleDataGroupItemIsStringType = (
    configOptions: ConfigOption,
    metadata: ReportingMetadata,
    orderItemConfig: SelectConfig,
    orderDirectionConfig: SelectConfig,
    limitConfig: SelectConfig,
) => {
    // Data Order item is string

    // can order by the Data Order item or any series item
    orderItemConfig.disabled = false
    orderItemConfig.tooltip = undefined
    orderItemConfig.options = configOptions.series
        .filter((x) => !x.isChildSeries && x.visible && x.dataItem)
        .map((ser: Series): Option => {
            let dataItem = ser.dataItem
            if (dataItem === DataGroupItem) {
                dataItem = configOptions.xAxisItem
            }
            const seriesDataItemWithAgg = `${dataItem}_${ser.aggregation}`
            return {
                label: `${getDataItemDefinitionByDataItem(dataItem, configOptions.dataType, metadata).displayName} (${
                    ser.aggregation
                })`,
                value: seriesDataItemWithAgg,
                selected: seriesDataItemWithAgg === configOptions.orderItem,
            }
        })

    const noOrderItemsSelected = orderItemConfig.options.find((x) => x.selected) === undefined
    orderItemConfig.options = orderItemConfig.options.concat([
        {
            label: `${
                getDataItemDefinitionByDataItem(configOptions.xAxisItem, configOptions.dataType, metadata).displayName
            }`,
            value: configOptions.xAxisItem,
            selected: configOptions.xAxisItem === configOptions.orderItem || noOrderItemsSelected,
        },
    ])

    // order Direction
    orderDirectionConfig.disabled = false
    orderDirectionConfig.tooltip = undefined
    orderDirectionConfig.options = [
        {
            label: 'Increasing',
            value: OrderDirectionAsc,
            selected: configOptions.orderDirection === OrderDirectionAsc || !configOptions.orderDirection,
        },
        {
            label: 'Decreasing',
            value: OrderDirectionDesc,
            selected: configOptions.orderDirection === OrderDirectionDesc,
        },
    ]

    // limiting
    limitConfig.disabled = false
    limitConfig.tooltip = undefined
    limitConfig.options = [
        { label: 'Maximum (100)', value: '100', selected: configOptions.limitResults.toString() === '100' },
        {
            label: '10',
            value: '10',
            selected: configOptions.limitResults.toString() === '10' || configOptions.limitResults === 0,
        },
        { label: '25', value: '25', selected: configOptions.limitResults.toString() === '25' },
        { label: '50', value: '50', selected: configOptions.limitResults.toString() === '50' },
    ]
}

const getDisabledSelectOption = (label: string): Option => ({ label, value: 'None', selected: true })

const handleDataGroupItemIsNumericType = (
    configOptions: ConfigOption,
    metadata: ReportingMetadata,
    groupMethodConfig: SelectConfig,
    binSizesConfig: SelectConfig,
) => {
    // Data Order item is numeric

    // group method options
    groupMethodConfig.disabled = false
    groupMethodConfig.tooltip = undefined
    groupMethodConfig.options = getDefaultNumericGroupMethods()

    // select the item based on the config
    let selectedGroupMethod = groupMethodConfig.options.find((x: Option) => x.value === configOptions.xAxisGrouping)!
    if (selectedGroupMethod === undefined) {
        // fallback on histogram as the default selected item
        selectedGroupMethod = groupMethodConfig.options.find((x: Option) => x.value === 'HistogramBins')!
    }

    selectedGroupMethod.selected = true

    // bin options
    binSizesConfig.disabled = false
    binSizesConfig.tooltip = undefined

    // const selectedGroupMethod2: Option = groupMethodConfig.options.find((x: Option) => x.selected === true)!
    if (selectedGroupMethod.value === 'None') {
        binSizesConfig.disabled = true
        binSizesConfig.tooltip = 'Bin Size does not apply to this grouping method'
        binSizesConfig.options = [getDisabledSelectOption('NA')]
    } else if (
        selectedGroupMethod.value === 'EffectivenessThresholds' ||
        selectedGroupMethod.value === 'ReservoirThresholds' ||
        selectedGroupMethod.value === 'PercentEffThresholds'
    ) {
        // user cannot change bin sizes for these "palette"-based groupings
        binSizesConfig.disabled = true
        binSizesConfig.tooltip = 'Bin Size is automatic in this grouping method'
        binSizesConfig.options = [getDisabledSelectOption('Auto')]
    } else {
        // Histogram grouping:
        // default bin sizes come from metadata
        binSizesConfig.options = metadata.binSizes.map((binSize: IdLabelSet) => ({
            label: binSize.label,
            value: binSize.id.toString(),
        }))

        // select the user-configured bin size (or add it if not in the metadata)
        const selectedBinSize = configOptions.binSize || 5
        const binSizeItem = binSizesConfig.options.find((x: Option) => x.value === selectedBinSize.toString())
        if (binSizeItem) {
            binSizeItem.selected = true
        } else {
            binSizesConfig.options.push({
                label: selectedBinSize.toString(),
                value: selectedBinSize.toString(),
                selected: true,
            })
        }

        // finally, sort the bin sizes
        sortNumericValueOptions(binSizesConfig)
    }
}

const NoLabelMarginTop = 'mt-4'

const ReportConfiguration = (props: { fromDashboard?: boolean; libraryReport?: ReportDialogConfig }) => {
    const reportingApi = useMemo(() => globals.getApi().getReportingApi(), [])
    const params = useParams() as any
    const history = useHistory()
    const dispatch = useDispatch()
    const reportId = Number(params.id) || 0
    const [reportingMetadata, setReportConfigMetadata] = useState<ReportingMetadata | null>(null)
    const [report, setReport] = useState<ReportDialogConfig | null>(null)
    const [configHeight, setConfigHeight] = useState(0)
    const [validatedForm, setValidatedForm] = useState(false)
    const [dialogMode, setDialogMode] = useState<DialogMode>('None')

    const scenarioSelection: ScenarioSelection | null = report && getScenarioSelection(report)
    const unusedColors = useSelector<RootState, string[]>((x) => x.reports.unusedReportSeriesColors)
    const fullColorPalette = useSelector<RootState, string[]>((x) => x.reports.fullColorPalette)

    useEffect(() => {
        const loadMetadata = async () => {
            const metadata = await reportingApi.getReportsConfigMetadata()
            setReportConfigMetadata(metadata)
        }
        const loadData = async () => {
            const rpt = await reportingApi.getReport(reportId)
            await setReport(rpt)
            await loadMetadata()
        }
        const newReport = async () => {
            await loadMetadata()
            setReport(getNewReport())
        }
        const showLibraryReport = async (libraryReport: ReportDialogConfig) => {
            await loadMetadata()
            setReport(libraryReport)
        }

        if (reportId) {
            loadData()
        } else if (props.libraryReport) {
            showLibraryReport(props.libraryReport)
        } else {
            newReport()
        }
    }, [props.libraryReport, reportingApi, reportId])

    useEffect(() => {
        if (!reportingMetadata) return
        dispatch(reportsActions.setFullColorPalette(reportingMetadata.colorPalette))
        dispatch(reportsActions.setUnusedReportSeriesColors(reportingMetadata.unusedPaletteColors))
    }, [dispatch, reportingMetadata, reportingMetadata?.unusedPaletteColors, reportingMetadata?.colorPalette])

    const closeFilterDialogCallback = useCallback((state: DialogResultEnum, updatedQuery?: QueryBuilder) => {
        if (state === DialogResultEnum.Completed && updatedQuery) {
            setReport((previous) => {
                const updatedReport = { ...previous! }
                updatedReport.configOptions = { ...previous!.configOptions! }
                updatedReport.configOptions!.queryBuilderRules = { ...updatedQuery }
                return updatedReport
            })
        }
        setDialogMode('None')
    }, [])

    if (!report) {
        return <p>Loading...</p>
    }

    const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
        // prevent usual form submission
        event.preventDefault()
        event.stopPropagation()

        const form = event.target as HTMLFormElement

        let invalid = false
        if (form.checkValidity() === false) {
            setValidatedForm(true)
            invalid = true
        }

        const hasInvalidScenarioConfiguration =
            report.overrideDashboardScenarioSelection === true &&
            report.includeAllMyScenarios === false &&
            report.includeAllScenariosSharedwMe === false &&
            report.selectedScenarioIds.length === 0
        if (hasInvalidScenarioConfiguration) {
            // todo: have validation on specific inputs like hazardClass config page
            toast.error('Must select at least 1 scenario')
            invalid = true
        }

        const configOptions = report.configOptions
        if (configOptions) {
            const series = configOptions.series
            if (series.length === 0) {
                // todo: have validation on specific inputs like hazardClass config page
                toast.error('Must add at least 1 series')
                invalid = true
            }
            if (series.filter((x) => !x.dataItem || !x.aggregation).length > 0) {
                // todo: have validation on specific inputs like hazardClass config page
                toast.error('Please select Data Items and Aggregations for every series')
                invalid = true
            }
        }

        if (invalid) {
            // keep the form open, let the user fix the issues
            return
        }

        if (configOptions) {
            // convert any "Data Order" items to the actual underlying data item
            for (let i = 0; i < configOptions.series.length; i++) {
                const ser = configOptions.series[i]
                if (ser.dataItem === DataGroupItem) {
                    ser.dataItem = configOptions.xAxisItem
                }
            }
        }

        if (props.libraryReport) {
            // it is a library report so don't save here; the library dialog does the saving
            dispatch(
                reportsActions.setEditLibraryReport({
                    report,
                    isUpdated: true,
                }),
            )
            return
        }

        let savedReport: Report | undefined
        try {
            dispatch(globalActions.showLoadingModal())
            savedReport = await reportingApi.saveReport(report)

            if (savedReport.requiresRecalculation) {
                await checkExistingRecalculationRequests(dispatch)
            }

            let navUrl = '/reports/'
            if (props.fromDashboard !== true) {
                navUrl += savedReport.id
            }
            history.push(navUrl)
        } catch (err: any) {
            handleApiError(err)
        } finally {
            dispatch(globalActions.hideLoadingModal())
        }
    }

    if (!reportingMetadata) {
        return <p>Loading...</p>
    }

    const getConfigCopy = (): ConfigOption => JSON.parse(JSON.stringify(report.configOptions))

    const getDataItemDefinition = (dataItem: string, configOptions: ConfigOption) =>
        getDataItemDefinitionByDataItem(dataItem, configOptions.dataType, reportingMetadata)

    const isExistingReport = !!reportId || !!props.libraryReport
    const title = isExistingReport ? 'Edit Report Configuration' : 'Create New Report'

    let dataFilterValue = ''
    if (report.configOptions?.queryBuilderRules) {
        dataFilterValue = convertQueryBuilderToSql(
            report.configOptions.queryBuilderRules,
            reportingMetadata.seriesDataItems[report.configOptions.dataType],
        )
    }

    // defaulting grouping, ordering and limiting to disabled - will change that below depending on the config.
    const groupMethodConfig: SelectConfig = {
        options: [getDisabledSelectOption('NA')],
        disabled: true,
        tooltip: 'Additional grouping types are only available for numeric data items',
    }
    const binSizesConfig: SelectConfig = { ...groupMethodConfig }

    const orderItemConfig: SelectConfig = {
        options: [getDisabledSelectOption('Auto')],
        disabled: true,
        tooltip: 'Ordering and Limiting are automatic on the selected Grouping method',
    }
    const orderDirectionConfig: SelectConfig = { ...orderItemConfig }
    const limitConfig: SelectConfig = { ...orderItemConfig }

    const dataGroupConfig: SelectConfig = report.configOptions
        ? {
              options: reportingMetadata.seriesDataItems[report.configOptions?.dataType]
                  .filter((item: ReportItemDefinition) => {
                      // not allowing grouping on the full datetime items, only hour, day, year, mm-dd, yyyy-mm-dd, etc.
                      return (
                          item.type.toLowerCase() !== 'datetime' &&
                          item.type.toLowerCase() !== 'date' &&
                          item.type.toLowerCase() !== 'time'
                      )
                  })
                  .map<Option>((item: ReportItemDefinition) => {
                      return {
                          value: item.dataItem,
                          label: item.displayName,
                          selected: item.dataItem === report.configOptions!.xAxisItem,
                      }
                  }),
          }
        : {
              options: [],
          }

    // group methods are dependent on the type of Data Order item
    let dataGroupItemType: string = ''
    let xAxisItemError = false
    if (report.configOptions) {
        try {
            dataGroupItemType = getDataItemDefinition(
                report.configOptions.xAxisItem,
                report.configOptions,
            ).type.toLowerCase()
        } catch (err: any) {
            xAxisItemError = true
        }

        if (xAxisItemError) {
            // remove the item, as it is broken
            report.configOptions.xAxisItem = ''
        } else if (dataGroupItemType === 'string') {
            handleDataGroupItemIsStringType(
                report.configOptions,
                reportingMetadata,
                orderItemConfig,
                orderDirectionConfig,
                limitConfig,
            )
        } else {
            handleDataGroupItemIsNumericType(report.configOptions, reportingMetadata, groupMethodConfig, binSizesConfig)
        }
    }

    return (
        <>
            {dialogMode === 'Filters' && (
                <ReportConfigFilterDialog
                    type={report.configOptions!.dataType === 'events' ? 'Events' : 'Schedules'}
                    dataItems={reportingMetadata.seriesDataItems[report.configOptions!.dataType]}
                    rules={report.configOptions!.queryBuilderRules}
                    closeCallback={closeFilterDialogCallback}
                />
            )}

            {dialogMode === 'ScenarioSelector' && scenarioSelection && (
                <ScenarioSelectorDialog
                    scenarios={reportingMetadata?.scenarios}
                    initialSelection={scenarioSelection}
                    closeCallback={(state: DialogResultEnum, updatedSelection?: ScenarioSelection) => {
                        if (state === DialogResultEnum.Completed && updatedSelection) {
                            const copy = JSON.parse(JSON.stringify(report))
                            setReport({
                                ...copy,
                                includeAllMyScenarios: updatedSelection.includeAllMyScenarios,
                                includeAllScenariosSharedwMe: updatedSelection.includeAllScenariosSharedwMe,
                                selectedScenarioIds: updatedSelection.selectedScenarioIds,
                            })
                        }
                        setDialogMode('None')
                    }}
                />
            )}
            <PageLayout headingContent={title} onMainContentHeightChange={setConfigHeight}>
                <Form key="form" aria-label="form" noValidate validated={validatedForm} onSubmit={submitHandler}>
                    <ConfigPageFormBody configHeight={configHeight}>
                        <IndentedBlock headingText="Details">
                            <ConfigurationRow
                                labelText="Name"
                                labelId="report-name"
                                subText="A unique name for the report"
                            >
                                <div className={NoLabelMarginTop}>
                                    <Form.Control
                                        type="text"
                                        autoFocus
                                        aria-labelledby="report-name"
                                        placeholder="Enter a name for the report"
                                        value={report.name}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                            setReport((prev) => ({ ...prev!, name: e.target.value }))
                                        }
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a report name
                                    </Form.Control.Feedback>
                                </div>
                            </ConfigurationRow>
                        </IndentedBlock>
                        <IndentedBlock headingText="Data Selection">
                            {report.configOptions && (
                                <ConfigurationRow
                                    labelText="Data Type"
                                    labelId="data-type"
                                    subText="Report on either Schedules or Events"
                                >
                                    <div className={NoLabelMarginTop}>
                                        <BigButton
                                            activated={report.configOptions.dataType === 'schedules'}
                                            disabled={isExistingReport}
                                            tooltip={
                                                isExistingReport
                                                    ? 'The data type cannot be changed once a report has been saved'
                                                    : ''
                                            }
                                            onClick={() => {
                                                const configCopy = getConfigCopy()
                                                configCopy.dataType = 'schedules'
                                                configCopy.xAxisItem = 'ID'
                                                configCopy.queryBuilderRules = undefined

                                                const availableSeriesItems =
                                                    reportingMetadata.seriesDataItems.schedules.map((x) => x.dataItem)

                                                configCopy.series = configCopy.series.filter(
                                                    (x) =>
                                                        x.dataItem === 'Data Category' ||
                                                        availableSeriesItems.includes(x.dataItem),
                                                )

                                                setReport((prev) => {
                                                    return { ...prev!, configOptions: configCopy }
                                                })
                                            }}
                                        >
                                            Schedules
                                        </BigButton>
                                        <BigButton
                                            activated={report.configOptions.dataType === 'events'}
                                            disabled={isExistingReport}
                                            tooltip={
                                                isExistingReport
                                                    ? 'The data type cannot be changed once a report has been saved'
                                                    : ''
                                            }
                                            onClick={() => {
                                                const configCopy = getConfigCopy()
                                                configCopy.dataType = 'events'
                                                configCopy.xAxisItem = 'Label'

                                                const availableSeriesItems =
                                                    reportingMetadata.seriesDataItems.events.map((x) => x.dataItem)

                                                configCopy.series = configCopy.series.filter(
                                                    (x) =>
                                                        x.dataItem === 'Data Category' ||
                                                        availableSeriesItems.includes(x.dataItem),
                                                )

                                                configCopy.queryBuilderRules = JSON.parse(
                                                    '{"condition":"AND","rules":[{"id":"Crewing","field":"Crewing","type":"boolean","input":"select","operator":"equal","value":[true]}],"valid":true}',
                                                )
                                                setReport((prev) => {
                                                    return { ...prev!, configOptions: configCopy }
                                                })
                                            }}
                                        >
                                            Events
                                        </BigButton>
                                    </div>
                                </ConfigurationRow>
                            )}
                            <ConfigurationRow
                                labelText="Scenario Selection"
                                labelId="scenario-selection"
                                subText="The scenarios to be included in the report data set"
                            >
                                {isDesktopMode() && (
                                    <ScenarioSelectorDesktop
                                        selectedScenarioIds={report.selectedScenarioIds}
                                        setSelectedScenarioIds={(val) =>
                                            setReport({ ...report, selectedScenarioIds: val })
                                        }
                                        includeAll={report.includeAllMyScenarios}
                                        combine={!report.overlayScenarios}
                                        setIncludeAll={(val) => {
                                            let selectedScenarioIds = report.selectedScenarioIds
                                            if (!val) {
                                                // changed from all to not all, so deselect everything
                                                selectedScenarioIds = ''
                                            }
                                            setReport({
                                                ...report,
                                                includeAllMyScenarios: val,
                                                selectedScenarioIds,
                                            })
                                        }}
                                        setCombine={(val) => setReport({ ...report, overlayScenarios: val === false })}
                                    />
                                )}
                                {!isDesktopMode() && (
                                    <ScenarioSelectorButton
                                        showCombineScenariosOption
                                        options={{
                                            selection: getScenarioSelection(report),
                                            combineScenarios: report.overlayScenarios !== true,
                                            override: report.overrideDashboardScenarioSelection,
                                            separateHazardClassPerScenario: false,
                                        }}
                                        selectScenariosClicked={() => setDialogMode('ScenarioSelector')}
                                        updateSelections={(options: ScenarioSelectionOptions) => {
                                            setReport({
                                                ...report,
                                                overrideDashboardScenarioSelection: options.override,
                                                overlayScenarios: options.combineScenarios !== true,
                                            })
                                        }}
                                    />
                                )}
                            </ConfigurationRow>
                            {report.configOptions && (
                                <ConfigurationRow
                                    labelText="Data Filter"
                                    labelId="data-filter"
                                    subText="Filter the report data using logic rules"
                                >
                                    <div className={NoLabelMarginTop}>
                                        <Form.Row>
                                            <Col sm="12" style={{ display: 'flex' }}>
                                                <Form.Control
                                                    style={{ fontSize: '0.95em' }}
                                                    id="txtFilter"
                                                    name="txtFilter"
                                                    type="text"
                                                    disabled
                                                    value={dataFilterValue}
                                                />
                                                <IconButton
                                                    style={{ marginLeft: 10, width: '30px', height: '36px' }}
                                                    onClick={() => setDialogMode('Filters')}
                                                    icon="bi-pencil"
                                                    tooltip="Edit the Data Filter"
                                                />
                                            </Col>
                                        </Form.Row>
                                    </div>
                                </ConfigurationRow>
                            )}
                        </IndentedBlock>
                        {report.configOptions && (
                            <>
                                <IndentedBlock
                                    headingText="Horizontal Axis"
                                    subheadingText="Configuration of the horizonal data item"
                                >
                                    <ConfigurationRow
                                        labelText="Data Category"
                                        labelId="data-category"
                                        subText="The category is the item displayed along the horizonal axis"
                                    >
                                        <Form.Row>
                                            <Col sm="6">
                                                <Form.Group>
                                                    <FormLabelCustom id="dataGroupLabel" htmlFor="dataGroup">
                                                        Data Item
                                                    </FormLabelCustom>
                                                    <Select
                                                        aria-labelledby="dataGroupLabel"
                                                        id="dataGroup"
                                                        name="dataGroup"
                                                        options={dataGroupConfig.options}
                                                        value={dataGroupConfig.options.find((x: Option) => x.selected)}
                                                        menuPlacement="auto"
                                                        onChange={(newValue: OnChangeValue<Option, false>) => {
                                                            const newDataGroupItem = newValue!.value
                                                            const configCopy = getConfigCopy()
                                                            // check to see if there is a series that uses this data group, change that series too
                                                            const dataGroupSeries = configCopy.series.filter(
                                                                (x) => x.dataItem === configCopy.xAxisItem,
                                                            )

                                                            const dataGroupType = getDataItemDefinitionByDataItem(
                                                                newDataGroupItem,
                                                                report.configOptions!.dataType,
                                                                reportingMetadata,
                                                            ).type

                                                            for (let i = 0; i < dataGroupSeries.length; i++) {
                                                                dataGroupSeries[i].dataItem = newDataGroupItem
                                                                if (
                                                                    dataGroupSeries[i].aggregation !== 'Count' &&
                                                                    dataGroupType === 'string'
                                                                ) {
                                                                    // string type items as data group only support Count
                                                                    dataGroupSeries[i].aggregation = 'Count'
                                                                }
                                                            }
                                                            configCopy.xAxisItem = newDataGroupItem

                                                            // update data model with logical defaults
                                                            const selectedDataGroupItemType = getDataItemDefinition(
                                                                newDataGroupItem,
                                                                report.configOptions!,
                                                            ).type.toLowerCase()

                                                            // set logic defaults for each group type, if no values set yet
                                                            if (selectedDataGroupItemType === 'string') {
                                                                // set defaults if no order previously selected
                                                                if (configCopy.orderItem === '') {
                                                                    configCopy.orderDirection = 'Asc'
                                                                    configCopy.limitResults = 10
                                                                }
                                                                // set order to new selected data group
                                                                configCopy.orderItem = newDataGroupItem
                                                                // set numeric data group options back to default
                                                                configCopy.xAxisGrouping = 'None'
                                                            } else {
                                                                // set defaults in no grouping previously selected
                                                                if (configCopy.xAxisGrouping === 'None') {
                                                                    configCopy.xAxisGrouping = 'HistogramBins'
                                                                    configCopy.binSize = 10
                                                                }
                                                                // set string data group options back to default
                                                                configCopy.orderItem = ''
                                                            }

                                                            setReport((prev) => {
                                                                return { ...prev!, configOptions: configCopy }
                                                            })
                                                        }}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Form.Row>
                                    </ConfigurationRow>
                                    <ConfigurationRow
                                        labelText="Data Category Grouping"
                                        labelId="data-category-grouping"
                                        subText="Apply grouping to horizontal axis (if the category data item is a numeric value)"
                                    >
                                        <Form.Row>
                                            <Col sm="6">
                                                <Form.Group>
                                                    <FormLabelCustom
                                                        id="groupMethodLabel"
                                                        htmlFor="groupMethodSelector"
                                                    >
                                                        Group Method
                                                    </FormLabelCustom>
                                                    <div title={groupMethodConfig.tooltip}>
                                                        <Select
                                                            id="groupMethodSelector"
                                                            name="groupMethod"
                                                            aria-labelledby="groupMethodLabel"
                                                            options={groupMethodConfig.options}
                                                            value={groupMethodConfig.options.find(
                                                                (x: Option) => x.selected,
                                                            )}
                                                            onChange={(newValue: OnChangeValue<Option, false>) => {
                                                                const configCopy = getConfigCopy()
                                                                configCopy.xAxisGrouping = newValue!.value
                                                                setReport((prev) => {
                                                                    return { ...prev!, configOptions: configCopy }
                                                                })
                                                            }}
                                                            isDisabled={groupMethodConfig.disabled}
                                                            menuPlacement="auto"
                                                        />
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col sm="6">
                                                <Form.Group>
                                                    <FormLabelCustom id="binSizeLabel" htmlFor="binSize">
                                                        Bin Size
                                                    </FormLabelCustom>
                                                    <div title={binSizesConfig.tooltip}>
                                                        <Select
                                                            id="binSize"
                                                            name="binSize"
                                                            aria-labelledby="binSizeLabel"
                                                            options={binSizesConfig.options}
                                                            value={binSizesConfig.options.find((x) => x.selected)}
                                                            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                                                                const newValue = parseInt(e.target.value)
                                                                if (Number.isNaN(newValue)) {
                                                                    return
                                                                }
                                                                // this handles the case of the user manually typing an ad-hoc number
                                                                const configCopy = getConfigCopy()
                                                                configCopy.binSize = newValue
                                                                setReport((prev) => {
                                                                    return { ...prev!, configOptions: configCopy }
                                                                })
                                                            }}
                                                            onChange={(newValue: OnChangeValue<Option, false>) => {
                                                                // user changes to a different value in the list
                                                                const configCopy = getConfigCopy()
                                                                configCopy.binSize = parseInt(newValue!.value)

                                                                setReport((prev) => {
                                                                    return { ...prev!, configOptions: configCopy }
                                                                })
                                                            }}
                                                            isDisabled={binSizesConfig.disabled}
                                                            menuPlacement="auto"
                                                        />
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                        </Form.Row>
                                    </ConfigurationRow>
                                </IndentedBlock>
                                <IndentedBlock
                                    headingText="Vertical Axis"
                                    subheadingText="Configuration of the vertical data items"
                                >
                                    <ConfigurationRow
                                        labelText="Data Series"
                                        labelId="data-series"
                                        subText="One or more data series to appear on the vertical axis"
                                    >
                                        <Form.Row>
                                            <Col>
                                                <Form.Group>
                                                    <FormLabelCustom>
                                                        Series
                                                        <IconButtonAdd
                                                            style={{ marginLeft: '5px', cursor: 'pointer' }}
                                                            onClick={() => {
                                                                // grab next available color and remove it from the state
                                                                const nextUnusedColor = unusedColors[0]
                                                                dispatch(
                                                                    reportsActions.setUnusedReportSeriesColors(
                                                                        unusedColors.slice(1),
                                                                    ),
                                                                )
                                                                const configCopy = getConfigCopy()
                                                                const series = configCopy.series
                                                                configCopy.series.push(
                                                                    getNewReportSeries(series, nextUnusedColor),
                                                                )

                                                                setReport((prev) => {
                                                                    return { ...prev!, configOptions: configCopy }
                                                                })
                                                            }}
                                                        />
                                                    </FormLabelCustom>
                                                    <ReportConfigDialogSeriesSelector
                                                        showColorPicker={
                                                            report.colorAssignmentMode === 'GradientPalette'
                                                        }
                                                        addUnusedColor={(color) =>
                                                            dispatch(
                                                                reportsActions.setUnusedReportSeriesColors([
                                                                    color,
                                                                    ...unusedColors,
                                                                ]),
                                                            )
                                                        }
                                                        reportDataType={report.configOptions.dataType}
                                                        metadata={reportingMetadata}
                                                        xAxisItem={report.configOptions.xAxisItem}
                                                        series={report.configOptions.series}
                                                        setSeries={(updatedSeries: Series[]) => {
                                                            const configCopy = getConfigCopy()
                                                            configCopy.series = updatedSeries

                                                            setReport((prev) => {
                                                                return { ...prev!, configOptions: configCopy }
                                                            })
                                                        }}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Form.Row>
                                    </ConfigurationRow>
                                </IndentedBlock>
                                <IndentedBlock headingText="Order & Limit">
                                    <ConfigurationRow
                                        labelText="Order"
                                        labelId="ordering"
                                        subText="Order the horizontal axis by category or series"
                                    >
                                        <Form.Row>
                                            <Col sm="6">
                                                <Form.Group>
                                                    <FormLabelCustom id="orderLabel" htmlFor="order">
                                                        Order Item
                                                    </FormLabelCustom>
                                                    <div
                                                        title={orderItemConfig.disabled ? orderItemConfig.tooltip : ''}
                                                    >
                                                        <Select
                                                            aria-labelledby="orderLabel"
                                                            id="order"
                                                            name="order"
                                                            options={orderItemConfig.options}
                                                            value={orderItemConfig.options.find((x) => x.selected)}
                                                            onChange={(newValue: OnChangeValue<Option, false>) => {
                                                                const configCopy = getConfigCopy()
                                                                configCopy.orderItem = newValue!.value

                                                                setReport((prev) => {
                                                                    return { ...prev!, configOptions: configCopy }
                                                                })
                                                            }}
                                                            isDisabled={orderItemConfig.disabled}
                                                            menuPlacement="auto"
                                                        />
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col sm="6">
                                                <Form.Group>
                                                    <FormLabelCustom id="orderDirectionLabel" htmlFor="orderDirection">
                                                        Order Direction
                                                    </FormLabelCustom>
                                                    <div
                                                        title={
                                                            orderDirectionConfig.disabled
                                                                ? orderDirectionConfig.tooltip
                                                                : ''
                                                        }
                                                    >
                                                        <Select
                                                            aria-labelledby="orderDirectionLabel"
                                                            name="orderDirection"
                                                            id="orderDirection"
                                                            options={orderDirectionConfig.options}
                                                            value={orderDirectionConfig.options.find((x) => x.selected)}
                                                            onChange={(newValue: OnChangeValue<Option, false>) => {
                                                                const configCopy = getConfigCopy()
                                                                configCopy.orderDirection =
                                                                    newValue!.value === OrderDirectionAsc
                                                                        ? OrderDirectionAsc
                                                                        : OrderDirectionDesc

                                                                setReport((prev) => {
                                                                    return { ...prev!, configOptions: configCopy }
                                                                })
                                                            }}
                                                            isDisabled={orderDirectionConfig.disabled}
                                                            menuPlacement="auto"
                                                        />
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                        </Form.Row>
                                    </ConfigurationRow>
                                    <ConfigurationRow
                                        labelText="Limit Data"
                                        labelId="limit-data"
                                        subText="Limit the number of categories reported"
                                    >
                                        <Form.Row>
                                            <Col sm="6">
                                                <Form.Group>
                                                    <FormLabelCustom id="limitLabel" htmlFor="limit">
                                                        Data Category Limit
                                                    </FormLabelCustom>
                                                    <div title={limitConfig.disabled ? limitConfig.tooltip : ''}>
                                                        <Select
                                                            aria-labelledby="limitLabel"
                                                            name="limit"
                                                            id="limit"
                                                            options={limitConfig.options}
                                                            value={limitConfig.options.find((x) => x.selected)}
                                                            onChange={(newValue: OnChangeValue<Option, false>) => {
                                                                const configCopy = getConfigCopy()
                                                                configCopy.limitResults = parseInt(newValue!.value)
                                                                setReport((prev) => {
                                                                    return { ...prev!, configOptions: configCopy }
                                                                })
                                                            }}
                                                            isDisabled={limitConfig.disabled}
                                                            menuPlacement="auto"
                                                        />
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                        </Form.Row>
                                    </ConfigurationRow>
                                </IndentedBlock>
                            </>
                        )}
                        {!report.configOptions && (
                            <Alert variant="warning">The data configuration in this report cannot be changed.</Alert>
                        )}
                        <IndentedBlock headingText="Report Appearance">
                            <ConfigurationRow
                                labelText="Axis Titles"
                                labelId="axis-titles"
                                subText="Axis titles are automatic, but can be overridden"
                            >
                                <Form.Row>
                                    <Col sm="6">
                                        <Form.Group>
                                            <FormLabelCustom htmlFor="txtGroupAxisTitle">
                                                Data Category Axis
                                            </FormLabelCustom>
                                            <Form.Control
                                                id="txtGroupAxisTitle"
                                                name="txtGroupAxisTitle"
                                                type="text"
                                                placeholder="[Automatic Title]"
                                                value={report.xAxisLabel ?? ''}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                    setReport((prev) => {
                                                        return { ...prev!, xAxisLabel: e.target.value }
                                                    })
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm="6">
                                        <Form.Group>
                                            <FormLabelCustom htmlFor="txtSeriesAxisTitle">
                                                Data Series Axis
                                            </FormLabelCustom>
                                            <Form.Control
                                                id="txtSeriesAxisTitle"
                                                name="txtSeriesAxisTitle"
                                                type="text"
                                                placeholder="[Automatic Title]"
                                                value={report.yAxisLabel ?? ''}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                    setReport((prev) => {
                                                        return { ...prev!, yAxisLabel: e.target.value }
                                                    })
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                            </ConfigurationRow>
                            <ConfigurationRow
                                labelText="Color Palette"
                                labelId="color-palette"
                                subText="Simple Palette will automatically assign a color for each series.  Palette with Gradients allows you to choose the color for each series item, while the same series item in different scenarios in the report will be represented as a gradient of the selected color"
                            >
                                <div className={NoLabelMarginTop}>
                                    <BigButton
                                        activated={report.colorAssignmentMode === 'SimplePalette'}
                                        onClick={() =>
                                            setReport((prev) => ({ ...prev!, colorAssignmentMode: 'SimplePalette' }))
                                        }
                                    >
                                        Simple Palette
                                    </BigButton>
                                    <BigButton
                                        activated={report.colorAssignmentMode === 'GradientPalette'}
                                        onClick={() => {
                                            if (report.configOptions) {
                                                // gradient mode uses explicit colors; reset the series back to the default colors, in order
                                                let usedColorsCount = 0
                                                report.configOptions.series
                                                    .filter((x) => x.isChildSeries === false)
                                                    .forEach((ser) => {
                                                        ser.color = fullColorPalette[usedColorsCount]
                                                        usedColorsCount += 1
                                                    })
                                                const updatedUnusedColors = [...fullColorPalette].slice(usedColorsCount)
                                                dispatch(
                                                    reportsActions.setUnusedReportSeriesColors(updatedUnusedColors),
                                                )
                                            }
                                            setReport((prev) => {
                                                return { ...prev!, colorAssignmentMode: 'GradientPalette' }
                                            })
                                        }}
                                    >
                                        Palette with Gradients
                                    </BigButton>
                                </div>
                            </ConfigurationRow>

                            <ConfigurationRow
                                labelText="Optional Features"
                                labelId="optional-features"
                                subText="Show or hide optional features of the report"
                            >
                                <div className={NoLabelMarginTop}>
                                    <Form.Row>
                                        <Col>
                                            <Form.Check
                                                id="chkShowLegend"
                                                label="Show Legend"
                                                type="checkbox"
                                                checked={report.showLegend}
                                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                    setReport((prev) => {
                                                        return { ...prev!, showLegend: e.target.checked }
                                                    })
                                                }}
                                            />
                                            <Form.Check
                                                id="chkShowAxisTitles"
                                                label="Show Axis Titles"
                                                type="checkbox"
                                                checked={report.showAxisTitles}
                                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                    setReport((prev) => {
                                                        return { ...prev!, showAxisTitles: e.target.checked }
                                                    })
                                                }}
                                            />
                                            <Form.Check
                                                id="chkShowDataGroupAxisLabels"
                                                label="Show Data Category Axis Labels"
                                                type="checkbox"
                                                checked={report.showXAxisLabels}
                                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                    setReport((prev) => {
                                                        return { ...prev!, showXAxisLabels: e.target.checked }
                                                    })
                                                }}
                                            />
                                            <Form.Check
                                                id="chkShowDataSeriesAxisLabels"
                                                label="Show Data Series Axis Labels"
                                                type="checkbox"
                                                checked={report.showYAxisLabels}
                                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                    setReport((prev) => {
                                                        return { ...prev!, showYAxisLabels: e.target.checked }
                                                    })
                                                }}
                                            />
                                            <Form.Check
                                                id="chkShowValueLabels"
                                                label="Show Value Labels"
                                                type="checkbox"
                                                checked={report.showValueLabels}
                                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                    setReport((prev) => {
                                                        return { ...prev!, showValueLabels: e.target.checked }
                                                    })
                                                }}
                                            />
                                            <Form.Check
                                                id="chkShowHorizontalGridLines"
                                                label="Show Horizontal Gridlines"
                                                type="checkbox"
                                                checked={report.showHorizontalGridLines}
                                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                    setReport((prev) => {
                                                        return { ...prev!, showHorizontalGridLines: e.target.checked }
                                                    })
                                                }}
                                            />
                                        </Col>
                                    </Form.Row>
                                </div>
                            </ConfigurationRow>
                        </IndentedBlock>
                    </ConfigPageFormBody>
                    <Footer
                        reportId={reportId}
                        fromDashboard={props.fromDashboard}
                        isLibraryReport={!!props.libraryReport}
                    />
                </Form>
            </PageLayout>
        </>
    )
}

const Footer = (props: { fromDashboard?: boolean; reportId: number; isLibraryReport?: boolean }) => {
    const history = useHistory()
    const dispatch = useDispatch()
    let okButtonText = props.isLibraryReport ? 'OK' : 'Create'
    if (props.reportId) {
        okButtonText = 'Save'
    }
    // extra padding keeps the buttons right-aligned with the scrolling content above because the scrollbar takes up space
    const extraRightPadding = 25
    return (
        <div style={{ display: 'flex', justifyContent: 'end', paddingRight: extraRightPadding }}>
            <PaddedCenteredContent>
                <ButtonCustom isLarge type="submit" variant="primary">
                    {okButtonText}
                </ButtonCustom>
                <ButtonCustom
                    isLarge
                    toolbarLeftMargin
                    variant="secondary"
                    onClick={() => {
                        if (props.isLibraryReport) {
                            dispatch(reportsActions.setEditLibraryReport(null))
                            return
                        }
                        let navUrl = '/reports/'
                        if (props.reportId && props.fromDashboard !== true) {
                            navUrl += props.reportId.toString()
                        }
                        history.replace(navUrl)
                    }}
                >
                    Cancel
                </ButtonCustom>
            </PaddedCenteredContent>
        </div>
    )
}

export default ReportConfiguration
