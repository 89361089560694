export interface SleepDuration {
    duration: number
    time: Date
}

export interface LabelsShiftedDown {
    [key: number]: boolean
}

/**
 * Configuration values/constants which are used to render the timeline.
 */
export const Configs = {
    rowHeight: 30,
    headerSvgHeight: 60,
    svgWidth: 1100,
    daysPerRow: 7,
    topMargin: 15,
    weekRowsGapBetween: 15,
    nonCrewingColor: '#ccc',
    legendExtraYGap: 20,
    legendRectangleHeight: 25,
    legendRectangleWidth: 30,
    legendHeight: 190,
    legendItemsIndent: 40,
    legendItemsMarginTop: 25,
    legendItemsHorizontalSpaceBetween: 110,
    legendSectionMarginTop: 55,
    dateFontColor: '#666',
    dateFontSize: '12px',
    dateHeight: 15,
    boldFontWeight: 600,
    scheduleNameFontSize: '16px',
    scheduleNameMarginBottom: 30,
    daysOfWeekMarginBottom: 30,
    dutyLabelYOffsetStartLocation: 13,
    dutyLabelYOffsetEndLocation: 27,
    dutyLabelXOffsetStartLocation: -26,
    dutyLabelXOffsetEndLocation: 3,
}

export const calculateExtraSleepLabelYOffset = (
    dataIndex: number,
    data: SleepDuration[],
    labelShiftedDown: LabelsShiftedDown,
) => {
    let extraYOffset = 0
    let labelIsTooCloseToPrevious = false
    if (dataIndex > 0) {
        const prevSleepTime = data[dataIndex - 1].time
        if (labelShiftedDown[prevSleepTime.getTime()] !== true) {
            const sleepTime = data[dataIndex].time.getTime()
            const timeDifferenceInHours = (sleepTime - prevSleepTime.getTime()) / (60 * 60 * 1000)
            labelIsTooCloseToPrevious = timeDifferenceInHours < 8
            if (labelIsTooCloseToPrevious) {
                // magic offset amount to shift the label down
                // but still fit in the row
                extraYOffset = 12
            }
            labelShiftedDown[sleepTime] = labelIsTooCloseToPrevious
        }
    }
    return extraYOffset
}
