import { useEffect, useRef, useState } from 'react'
import globals from 'services/global/globals'
import { DetailData } from 'types/DetailItem'
import GraphSchedule from 'types/GraphSchedule'
import { parseScheduleDetail } from 'types/interfaces'
import Schedule from 'types/Schedule'
import { parseScheduleEvent } from 'types/ScheduleEvent'
import { createLightIntervals, prepDataForGraph } from '../ScheduleDetails/EffectivenessGraph/EffectivenessGraph'
import ScheduleTimelineRenderer from './ScheduleTimelineRenderer'
import { Configs } from './ScheduleTimelineUtils'

const getWindow = () => {
    return window as any
}

const notifySfcScheduleRendered = () => {
    const w = getWindow()
    // prevent double-firing in development mode
    if (!w.notifyScheduleTimelineLoadedComplete) {
        w.safteFastConsole.notifyScheduleTimelineLoaded?.()
        w.notifyScheduleTimelineLoadedComplete = true
    }
}

const MultiScheduleTimeline = () => {
    const graphContainer: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null)
    const [scheduleData, setScheduleData] = useState<{
        dayOfWeek: 0 | 1 | 2 | 3 | 4 | 5 | 6
        schedule: Schedule
    } | null>(null)
    const api = globals.getApi()

    useEffect(() => {
        if (scheduleData) {
            const { schedule, dayOfWeek } = scheduleData
            if (dayOfWeek > 6 || dayOfWeek < 0) {
                throw new Error('Invalid day of week')
            }
            const graphSchedule = new GraphSchedule(
                schedule.id,
                schedule.scenarioId,
                schedule.name,
                schedule.modified,
                schedule.scenarioName,
                schedule.scenarioParameters,
                schedule.events,
                schedule.baseLocation,
                schedule.viewSettings,
                schedule.detailDataWithTimelineTicks!,
                createLightIntervals(schedule.detailDataWithTimelineTicks!.detailData),
                schedule.colorPalettes,
            )

            new ScheduleTimelineRenderer(graphContainer.current!, graphSchedule, dayOfWeek).render()

            // hack to notify SFC that the timeline has loaded
            // could go through the api, but this is going away in v7.
            notifySfcScheduleRendered()
        }
    }, [scheduleData, api])

    useEffect(() => {
        const loadData = async () => {
            // api call but actually goes to SFC through proxy
            const [loadedSchedule] = await api.getScheduleWithDetails(0)
            // not sure why this is not parsed already; could probably be generalized.
            loadedSchedule.events = loadedSchedule.events.map((x) => parseScheduleEvent(x, loadedSchedule.modified))
            const detailData: DetailData[] = JSON.parse(loadedSchedule.detailItemsJson ?? '[]').map(parseScheduleDetail)
            loadedSchedule.detailDataWithTimelineTicks = prepDataForGraph(detailData)
            const dayOfWeekStart = await getWindow().safteFastConsole.getDayOfWeekStart()
            setScheduleData({ dayOfWeek: dayOfWeekStart, schedule: loadedSchedule })
        }

        loadData()
    }, [api])

    return (
        <div>
            <div ref={graphContainer} />
            <div style={{ marginLeft: Configs.legendItemsIndent }}>
                <p style={{ fontWeight: Configs.boldFontWeight }}>Instructions:</p>
                <p>
                    The chart alerts crewmembers to periods of reduced alertness due to sleep and time-of-day factors.
                    The chart is a guide based on predictions, not a prescription or evaluation of the safety of the
                    operation.
                </p>
                <p>
                    Sleep pattern is crucial for determining Effectiveness (Alertness); getting less sleep than
                    predicted lowers it while more sleep improves it.
                </p>
                <p>
                    Crewmembers should plan sleep to match or exceed predicted amounts for an average person. The exact
                    timing of sleep is usually not crucial.
                </p>
                <p style={{ fontWeight: Configs.boldFontWeight }}>
                    Even with acceptable predicted performance, a crewmember&apos;s judgment of fatigue overrides the
                    chart.
                </p>
                <p>
                    Known limitations include factors impacting sleep opportunities, operational conditions, workload,
                    and general uncertainty of forecasting for individuals.
                </p>
                <p>
                    This chart does not factor personal health, personal stress/workload, personal quality of sleep and
                    multiple externals affecting day of operations.
                </p>
            </div>
        </div>
    )
}

export default MultiScheduleTimeline
